import { Button, List } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { fetchNotifications, deleteNotifications, selectAllNotifications } from "../store/notifications";
import useStyles from "../styles/notifications";
import Notification  from "./notification";
import {useTranslation} from 'react-i18next';

function Notifications() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector(selectAllNotifications);
  useEffect(() => {
    if (!notifications.length) dispatch(fetchNotifications());
  }, [notifications.length, dispatch]);

  const classes = useStyles();
  return (
    <div className={classes.frame}>
      <List className={classes.notificationList}>
      {notifications.map((item) => 
        <Link to={"/post/" + item.post_id} key={item.id} className={classes.link}>
          <Notification item={item} />
        </Link>
      )}
      </List>
      <Button variant="contained" color="primary" className={classes.clearButton} 
        onClick={() => dispatch(deleteNotifications())}>
        {t('notifications.clear')}
      </Button>
    </div>
  );
}

export default Notifications;
