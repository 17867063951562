import { storeSubscription } from "../services/pushnotificiations";

const SESS_KEY = 'pnPermissionRequested';
const VAPID_KEY = "BK-Z_5cssz6dYkV2ixgPLj-DLMFUewN5acru5UMgj0Zd_x5cVnCTRxuxWi65GwIyOVbE_TtLSnLPLmdiwteb8nc";

function isSupported() {
  return ('serviceWorker' in navigator) &&
         ('Notification' in window) &&
         ('PushManager' in window) &&
         ('showNotification' in ServiceWorkerRegistration.prototype);
}

function alreadyAttemptedSubscribe() {
  return sessionStorage.getItem(SESS_KEY) === 'true' ? true : false;
}

async function subscribe() {
  return navigator.serviceWorker.ready.then(reg => {
    sessionStorage.setItem(SESS_KEY, true);
    return reg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: VAPID_KEY
    });
  })
}

async function unsubscribe() {
  return navigator.serviceWorker.ready.then(reg => {
    return reg.pushManager.getSubscription(sub => {
      if (!sub) return true;
      return sub.unsubscribe()
        .then(() => {return true})
        .catch(err => {return false})
    })
  })
}

async function setEnabled(enable) {
  if (enable) {
    return Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        return subscribe().then(sub => {
          return storeSubscription(sub.toJSON()).then(() => true).catch(err => {
            console.error(err);
            return false;
          })
        }).catch(err => {
          console.error(err);
          return false;
        })
      }
      else {
        console.info("Permission denied after popup.");
        return false;
      }
    });
  }
  else {
    return unsubscribe();
  }
}

async function refresh() {
  if (!isSupported()) return;
  if (Notification.permission !== 'granted') return;
  if (alreadyAttemptedSubscribe()) return;
  subscribe().then(sub => {
    storeSubscription(sub.toJSON()).then(() => {
      console.info("Stored subscription successfully.")
    })
  });
}

const methods = {
  isSupported,
  refresh,
  setEnabled,
}

export default methods;