import {server} from './server';

export const getNotifications = async () => {
  const resp = await server().get('/notifications');
  return resp.data;
};

export const clearNotifications = async () => {
  const resp = await server().delete('/notifications');
  return resp.data;
};
