import { Card, CardActions, CardContent, CardMedia, IconButton, Typography, useTheme } from "@material-ui/core";
import { Delete, Edit, Share } from '@material-ui/icons';
import { useAuth } from './auth/provider';
import useStyles from '../styles/post';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import axios from "axios";
import { pathnamePrefix } from "../utils/browser";
import PostAttributes from "./postattributes";
import { useDispatch } from "react-redux";
import { erasePost } from "../store/posts";
import {useTranslation} from 'react-i18next';

function Post(props) {
  const {t} = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDeleteBtnClick = (e) => {
    e.preventDefault();
    // confirm('Are you sure you want to delete this post?');
    dispatch(erasePost({postId:props.post.id}));
  }
  const handleEditBtnClick = (e) => {
    e.preventDefault();
    history.push(pathnamePrefix() + '/post/' + props.post.id + '/edit', props.post);
  };

  function share(data) {
    navigator.share(data).then(() => {
      console.log('Shared');
    }).catch((err) => {
      console.error('Error in sharing:', err);
    });
  }
  const handleShareBtnClick = (e) => {
    e.preventDefault();
    if (props.post.img_url) {
      axios.get(props.post.img_url, {responseType: 'blob'})
        .then((resp) => {
          var file = new File([resp.data], "picture.jpg", {type: 'image/jpeg'});
          share({
            title: t('post.shared'),
            text: props.post.text,
            files: [file]
          });
        });
    }
    else {
      share({
        title: t('post.shared'),
        text: props.post.text
      });
    }
  };

  return (
    <Card className={classes.card} >
      {props.post && props.post.img_url && <CardMedia image={props.post.img_url} className={classes.cardMedia} />}
      {props.post && props.post.text && <CardContent className={classes.cardContent}>{props.post.text}</CardContent>}
      {props.post && props.post.post_id && <PostAttributes post={props.post} compact />}
      <CardActions className={classes.cardActions}>
        <Typography className={classes.actionText} color="textSecondary" variant="subtitle2">
          {props.post && moment(props.post.created_at).format('MMM D Y, h:mm a')}
        </Typography>
        {(auth.user.id === props.box.farmer_id) &&
          (<>
            <IconButton aria-label="Delete" style={{color:theme.palette.text.secondary}}
            onClick={handleDeleteBtnClick}>
              <Delete />
            </IconButton>
            {/* {moment().isBefore(moment(props.post.created_at).add(2, 'days')) && */}
            <IconButton aria-label="Edit" style={{color:theme.palette.text.secondary}}
              onClick={handleEditBtnClick}>
              <Edit />
            </IconButton>
          </>)
        }
        {navigator.share ? 
          <IconButton aria-label="Share" style={{color:theme.palette.text.secondary}}
            onClick={handleShareBtnClick}>
            <Share />
          </IconButton> : null
        }
      </CardActions>
    </Card>
  );
}

export default Post;