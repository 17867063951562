import { Button, ButtonGroup, Card, CardMedia, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import useStyles from "../styles/posteditor";
import { CameraAlt, PhotoLibrary } from "@material-ui/icons";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostById, selectPostById } from "../store/posts";
import { DEFAULT_ATTRS } from "../utils/defaults";
import MoveBoxDialog from "./moveboxdialog";
import { fetchBoxes, selectBoxById, selectBoxByPost } from "../store/boxes";
import { fetchLocations, selectLocationByBox, selectLocationByPost } from "../store/locations";
import SplitBoxDialog from "./splitboxdialog";
import {useTranslation} from 'react-i18next';

function PostEditor(props) {
  const {t} = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const [camState, setCamState] = useState(0);
  
  //webcam
  const constraints = {
    aspectRatio: {exact: 1.0},
    facingMode: "environment",
    width: {ideal: 1080}, 
    height: {ideal: 1080}
  };
  const camRef = useRef(null);
  const [image, setImage] = useState(null);
  const capture = useCallback(() => {
    const imageSrc = camRef.current.getScreenshot();
    setImage(imageSrc);
    setCamState(2);
  }, [camRef]);

  const remove = () => {
    setImage(null);
    setCamState(0);
  };

  //image resize
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    const img = document.createElement('img');
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      console.log(img.naturalWidth, img.naturalHeight);

      let w = 1080, h = 1080;
      if (img.naturalWidth > img.naturalHeight)
        h *= (img.naturalHeight / img.naturalWidth);
      else
        w *= (img.naturalWidth / img.naturalHeight);

      canvas.width = w;
      canvas.height = h;
      ctx.drawImage(img, 0, 0, w, h);
      setImage(canvas.toDataURL('image/jpeg', 0.9));
      setCamState(2);
    }
    img.src = reader.result;
  });
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    if (!selectedFiles.length) return;
    reader.readAsDataURL(selectedFiles[0]);
  };

  //text
  const [text, setText] = useState('');
  const handleChange = (e) => {
    setText(e.target.value);
    props.onChange(text, image, attrs);
  };
  
  //attributes
  const [attrs, setAttrs] = useState(DEFAULT_ATTRS);
  const handleAttrChecked = (e) => {
    setAttrs({...attrs, [e.target.id]: e.target.checked});
    props.onChange(text, image, attrs);
  }
  const handleAttrValue = (e) => {
    setAttrs({...attrs, [e.target.name]: e.target.value});
    props.onChange(text, image, attrs);
  }

  const post = useSelector(state => selectPostById(state, props.postId));
  const location = useSelector(state => {
    return (props.boxId) ? selectLocationByBox(state, props.boxId) : selectLocationByPost(state, props.postId);
  });
  const box = useSelector(state => {
    return (props.boxId) ? selectBoxById(state, props.boxId) : selectBoxByPost(state, props.postId);
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (!box) dispatch(fetchBoxes());
    if (!location) dispatch(fetchLocations());
  }, [])

  //initialise default values from box state
  useEffect(() => {
    if (!box) return;
    setAttrs({...attrs, filledbroodframes:box.filledbroodframes, filledsuperframes:box.filledsuperframes});
  }, [box])

  //edit mode
  function getAttrsFromPost(post) {
    const attrs = {};
    for (let key in DEFAULT_ATTRS) {
      attrs[key] = post[key] || DEFAULT_ATTRS[key];
    }
    return attrs;
  }  
  useEffect(() => {
    if (props.postId && !post) {
      dispatch(fetchPostById({postId: props.postId}));
    }
    if (post) {
      setText(post.text);
      if (post.img_url) {
        setImage(post.img_url);
        setCamState(2);
      }
      const attrs = getAttrsFromPost(post);
      setAttrs(attrs);
      if (attrs.moved_id) setMoveBtnDisabled(true);
      if (attrs.split_id) setSplitBtnDisabled(true);
    }
  }, [props.postId, post, dispatch]);

  // webcam and image-rendering
  function renderCamera(s) {
    switch(s) {
      case 1:
        return (<Webcam ref={camRef} audio={false} videoConstraints={constraints} 
          screenshotFormat="image/jpeg" onClick={capture} forceScreenshotSourceSize />);
      case 2:
        props.onChange(text, image, attrs);
        return (<CardMedia className={classes.cardMedia} component="img" src={image} onClick={remove} />);
      case 0:
      default:
        props.onChange(text, null, attrs);
        return (
          <div className={classes.buttonBar}>
            <div className={classes.button} onClick={()=>setCamState(1)}>
              <CameraAlt fontSize="large" />
            </div>
            <label className={classes.button}>
              <PhotoLibrary fontSize="large" />
              <input type="file" accept="image/jpeg" onChange={handleFileChange} />
            </label>
          </div>
        );
    }
  }

  //dialogs
  const [moveBtnDisabled, setMoveBtnDisabled] = useState(false);
  const [moveBoxDialogOpen, setMoveBoxDialogOpen] = useState(false);
  const handleShowMoveBox = () => {
    setMoveBoxDialogOpen(true);
  }
  const handleMoveBoxCancel = () => {
    setMoveBoxDialogOpen(false);
  }
  const handleMove = (selectedLocationId, position) => {
    if (selectedLocationId === location.id) {
      alert(t('dialogs.selectanother'));
      return;
    }
    setAttrs({...attrs, moved_id: selectedLocationId, moved_position: position});
    props.onChange(text, image, attrs);
    setMoveBtnDisabled(true);
    setMoveBoxDialogOpen(false);
  }

  const [splitLabel, setSplitLabel] = useState('');
  const [splitBtnDisabled, setSplitBtnDisabled] = useState(false);
  const[splitBoxDialogOpen, setSplitBoxDialogOpen] = useState(false);
  const handleShowSplitBox = () => {
    setSplitBoxDialogOpen(true);
  }
  const handleSplitBoxCancel = () => {
    setSplitBoxDialogOpen(false);
  }
  const handleSplit = (selectedBoxId, numFrames) => {
    setAttrs({...attrs, split_id: selectedBoxId, split_numframes: numFrames});
    props.onChange(text, image, attrs);
    setSplitLabel("✔ ");
    setSplitBtnDisabled(true);
    setSplitBoxDialogOpen(false);
  }

  const handleNotImpl = () => {
    alert(t('notimpl'));
  }

  //layout
  return (
    <Card className={classes.card}>
      <div className={classes.camera}>
        {renderCamera(camState)}
      </div>
      <div className={classes.caption} style={{backgroundColor: theme.palette.background.default}}>
        <Typography className={classes.sectionname} variant="h6">{t('post.observations')}</Typography>
        <div className={classes.fields}>
          <div className={classes.fieldscol}>
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.eggs} id="eggs" onChange={handleAttrChecked} />
            } label={t('post.eggs')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.larvae} id="larvae" onChange={handleAttrChecked} />
            } label={t('post.larvae')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.queenspotted} id="queenspotted" onChange={handleAttrChecked} />
            } label={t('post.qspotted')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.newconstruction} id="newconstruction" onChange={handleAttrChecked} />
            } label={t('post.newconstruction')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.swarmed} id="swarmed" onChange={handleAttrChecked} />
            } label={t('post.swarmed')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.died} id="died" onChange={handleAttrChecked} />
            } label={t('post.destroyed')} />
          </div>
          <div className={classes.fieldscol}>
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.sealedworkers} id="sealedworkers" onChange={handleAttrChecked} />
            } label={t('post.sealedworkers')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.drones} id="drones" onChange={handleAttrChecked} />
            } label={t('post.drones')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.queenmissing} id="queenmissing" onChange={handleAttrChecked} />
            } label={t('post.qmissing')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.dry} id="dry" onChange={handleAttrChecked} />
            } label={t('post.dry')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.absconded} id="absconded" onChange={handleAttrChecked} />
            } label={t('post.absconded')} />
          </div>
        </div>
        <div className={classes.filledframes}>
          <TextField className={classes.filledframetextfield} label={t('post.filledbrood')} type="number" 
            name="filledbroodframes" onChange={handleAttrValue} value={attrs.filledbroodframes} />
          <TextField className={classes.filledframetextfield} label={t('post.filledsuper')} type="number" 
            name="filledsuperframes" onChange={handleAttrValue} value={attrs.filledsuperframes} disabled={!box?.numsupers} />
        </div>
        <div className={classes.filledframes}>
          <FormControl className={classes.selectbox}>
            <InputLabel id="queencells-label">{t('post.qcells')}</InputLabel>
            <Select name="queencells" labelId="quencells-label" value={attrs.queencells} onChange={handleAttrValue}>
              <MenuItem value={0}>{t('post.qcnone')}</MenuItem>
              <MenuItem value={1}>{t('post.qcemergency')}</MenuItem>
              <MenuItem value={2}>{t('post.qcswarm')}</MenuItem>
              <MenuItem value={3}>{t('post.qcsuperseed')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.selectbox}>
            <InputLabel id="symptom-label">{t('post.symptoms')}</InputLabel>
            <Select name="symptom" labelId="symptom-label" value={attrs.symptom} onChange={handleAttrValue}>
              <MenuItem value={0}>-</MenuItem>
              <MenuItem value={6}>{t('post.stnone')}</MenuItem>
              <MenuItem value={1}>{t('post.stpunctured')}</MenuItem>
              <MenuItem value={2}>{t('post.stfoul')}</MenuItem>
              <MenuItem value={3}>{t('post.stmelted')}</MenuItem>
              <MenuItem value={4}>{t('post.sttailup')}</MenuItem>
              <MenuItem value={5}>{t('post.stfungal')}</MenuItem>
              <MenuItem value={7}>{t('post.stdisoriented')}</MenuItem>
              <MenuItem value={8}>{t('post.stothers')}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Typography className={classes.sectionname} variant="h6">{t('post.actions')}</Typography>
        <div className={classes.fields}>
          <div className={classes.fieldscol}>
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.familygiven} id="familygiven" onChange={handleAttrChecked} />
            } label={t('post.familygiven')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.baseboardcleaned} id="baseboardcleaned" onChange={handleAttrChecked} />
            } label={t('post.basecleaned')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.superadded} id="superadded" onChange={handleAttrChecked} />
            } label={t('post.superadded')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.commissioned} id="commissioned" onChange={handleAttrChecked} />
            } label={t('post.commissioned')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.queencellgiven} id="queencellgiven" onChange={handleAttrChecked} />
            } label={t('post.qcellgiven')} />
          </div>
          <div className={classes.fieldscol}>
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.retiedcombs} id="retiedcombs" onChange={handleAttrChecked} />
            } label={t('post.retiedcombs')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.treatmentgiven} id="treatmentgiven" onChange={handleAttrChecked} />
            } label={t('post.treatment')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.superremoved} id="superremoved" onChange={handleAttrChecked} />
            } label={t('post.superremoved')} />
            <FormControlLabel control={
              <Checkbox color="primary" checked={attrs.decommissioned} id="decommissioned" onChange={handleAttrChecked} />
            } label={t('post.decommissioned')} />
          </div>
        </div>
        <div className={classes.filledframes}>
          <TextField className={classes.filledframetextfield} label={t('post.qcellsremoved')} type="number" 
            name="queencellsremoved" onChange={handleAttrValue} value={attrs.queencellsremoved} />
          <TextField className={classes.filledframetextfield} label={t('post.feedgiven')} type="number" 
            name="feedgiven" onChange={handleAttrValue} value={attrs.feedgiven} />
        </div>
        <div className={classes.filledframes}>
          <TextField className={classes.filledframetextfield} label={t('post.combsadded')} type="number" 
            name="combsadded" onChange={handleAttrValue} value={attrs.combsadded} />
          <TextField className={classes.filledframetextfield} label={t('post.combsremoved')} type="number" 
            name="combsremoved" onChange={handleAttrValue} value={attrs.combsremoved} />
        </div>
        <TextField className={classes.harvesttextfield} label={t('post.honeyharvested')} type="number" 
          name="framesharvested" onChange={handleAttrValue} value={attrs.framesharvested} />
        <ButtonGroup className={classes.btnrow} disableElevation>
          <Button className={classes.btnrowbtn} disabled={moveBtnDisabled} onClick={handleShowMoveBox}>
            {attrs.moved_id ? '✔ ' : ''}{t('post.moved')}
          </Button>
          <Button className={classes.btnrowbtn} onClick={handleNotImpl}>{t('post.isolated')}</Button>
        </ButtonGroup>
        <ButtonGroup className={classes.btnrow} disableElevation>
          <Button className={classes.btnrowbtn} onClick={handleNotImpl}>{t('post.filtered')}</Button>
          <Button className={classes.btnrowbtn} disabled={splitBtnDisabled} onClick={handleShowSplitBox}>
            {attrs.split_id ? '✔ ' : ''}{t('post.split')}
          </Button>
          <Button className={classes.btnrowbtn} onClick={handleNotImpl}>{t('post.merged')}</Button>
        </ButtonGroup>
        <TextField multiline label={t('post.writedetails')} className={classes.textField} 
          value={text} onChange={handleChange} variant="outlined" />
      </div>
      <MoveBoxDialog defaultPosition={box?.position} defaultLocation={location}
        open={moveBoxDialogOpen} onMove={handleMove} onCancel={handleMoveBoxCancel} />
      <SplitBoxDialog location={location} defaultBox={box} defaultNumFrames={3}
        open={splitBoxDialogOpen} onSplit={handleSplit} onCancel={handleSplitBoxCancel} />
    </Card>
  );
}

export default PostEditor;
