import {server} from './server';

export const getLocations = async () => {
  const resp = await server().get('/locations');
  return resp.data;
};

export const getLocation = async (locationId) => {
  const params = {location_id: locationId};

  const resp = await server().get('/locations', {params});
  return resp.data[0];
};

export const addLocation = async (name) => {
  const data = { name };

  const resp = await server().post('/locations', data);
  return resp.data[0];
};

export const editLocation = async (location_id, name) => {
  const data = { location_id, name };

  const resp = await server().put('/locations', data);
  return resp.data[0];
};

export const setLocationNotice = async (location_id, notice) => {
  const data = {location_id, notice};

  const resp = await server().post('/locations/notice', data);
  return resp.data[0];
};

export const getLocationSummary = async (location_id) => {
  const params = {location_id};

  const resp = await server().get('/locations/summary', {params});
  return resp.data;
};
