import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectLocationsByUser } from "../store/locations";
import useStyles from "../styles/moveboxdialog";
import { useAuth } from "./auth/provider";
import {useTranslation} from 'react-i18next';

function MoveBoxDialog(props) {
  const {t} = useTranslation();
  const open = props.open;

  const auth = useAuth();
  const locations = useSelector((state) => selectLocationsByUser(state, auth.user?.id));

  const [selectedLocationId, setSelectedLocationId] = useState(props.defaultLocation?.id);
  const handleLocationChange = (event) => {
    setSelectedLocationId(event.target.value);
  }

  const [position, setPosition] = useState(props.defaultPosition || 1);
  const [error, setError] = useState(false);
  const handlePositionChange = (event) => {
    setPosition(event.target.value);
    const val = parseInt(event.target.value);
    if (val && val > 0) {
      setError(false);
    }
    else {
      setError(true);
    }
  }

  const cancel = () => {
    setSelectedLocationId(props.defaultLocation?.id);
    setPosition(props.defaultPosition || 1);
    setError(false);
    props.onCancel();
  };

  const classes = useStyles();
  return (
    <Dialog open={open} onClose={cancel}>
      <DialogTitle>{t('dialogs.movebox')}</DialogTitle>

      <DialogContent>
        <InputLabel id="location-label-id">
          Location
          <Select 
            label={t('dialogs.location')} 
            className={classes.selectMenu}
            value={selectedLocationId}
            onChange={handleLocationChange}
            disabled={props.positionOnly}
          >
            {locations.map((location) => (
              <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
            ))}
          </Select>
        </InputLabel>

        <InputLabel id="position-label-id">
          {t('dialogs.position')}<br/>
          <TextField value={position} 
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            error={error} onChange={handlePositionChange} helperText={t('dialogs.numberhelp')} />
        </InputLabel>
      </DialogContent>

      <DialogActions>
        <Button onClick={cancel}>{t('dialogs.cancel')}</Button>
        <Button disabled={error} onClick={() => props.onMove(selectedLocationId, position)}>{t('dialogs.move')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MoveBoxDialog;