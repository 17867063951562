import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBoxById } from "../store/boxes";
import { selectLocationById } from "../store/locations";
import {useTranslation} from 'react-i18next';

function queenCellString(code, t) {
  switch(code) {
    default:
    case 0:
      return t('post.qcnone');
    case 1:
      return t('post.qcemergency');
    case 2:
      return t('post.qcswarm');
    case 3:
      return t('post.qcsuperseed');
  }
}

function symptomString(code, t) {
  switch(code) {
    default:
    case 0:
      return "-";
    case 1:
      return t('post.stpunctured');
    case 2:
      return t('post.stfoul');
    case 3:
      return t('post.stmelted');
    case 4:
      return t('post.sttailup');
    case 5:
      return t('post.stfungal');
    case 6:
      return t('post.stnone');
    case 7:
      return t('post.stdisoriented');
    case 8:
      return t('post.stothers');
  }
}

function PostAttributes(props) {
  const {t} = useTranslation();
  const post = props.post;

  const dispatch = useDispatch();
  const moved_location = useSelector(state => selectLocationById(state, post.moved_id));
  const split_box = useSelector(state => selectBoxById(state, post.split_id));

  const [movedText, setMovedText] = useState('');
  const [splitText, setSplitText] = useState('');
  useEffect(() => {
    if (post.moved_id) {
      setMovedText(t('post.boxmovedmsg', {locationName: moved_location?.name}))
    }
    if (post.split_id) {
      setSplitText(t('post.boxsplitmsg', {num: post.split_numframes, boxName:split_box?.name}))
    }
  }, [moved_location, split_box]);

  const css = {
    display: 'flex', 
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    padding:'16px', 
    justifyContent: 'space-around'
  }
  if (props.compact) {
    css.padding = '8px';
    css.justifyContent = 'space-between';
  }

  return (
    <div style={css}>
      <div style={{display: 'flex', flexDirection: 'column', margin: '0 4px 4px 0'}}>
        <i>{t('post.observations')}</i>
        {post.queenspotted && <span>✅ {t('post.qspotted')}</span>}
        {post.eggs && <span>✅ {t('post.eggs')}</span>}
        {post.larvae && <span>✅ {t('post.larvae')}</span>}
        {post.drones && <span>✅ {t('post.drones')}</span>}
        {post.sealedworkers && <span>✅ {t('post.sealedworkers')}</span>}
        {post.newconstruction && <span>✅ {t('post.newconstruction')}</span>}
        {post.dry && <span>✅ {t('post.dry')}</span>}
        {post.swarmed && <span>✅ {t('post.swarmed')}</span>}
        {post.absconded && <span>✅ {t('post.absconded')}</span>}
        {post.queenmissing && <span>✅ {t('post.qmissing')}</span>}
        {post.died && <span>✅ {t('post.destroyed')}</span>}        
        {post.filledbroodframes > 0 && <span>{t('post.filledbrood')}: {post.filledbroodframes}</span>}
        {post.filledsuperframes > 0 && <span>{t('post.filledsuper')}: {post.filledsuperframes}</span>}
        {post.queencells > 0 && <span>{t('post.qcells')}: {queenCellString(post.queencells, t)}</span>}
        {post.symptom > 0 && <span>{t('post.symptoms')}: {symptomString(post.symptom, t)}</span>}
      </div>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <i>{t('post.actions')}</i>
        {post.familygiven && <span>✅ {t('post.familygiven')}</span>}
        {post.commissioned && <span>✅ {t('post.commissioned')}</span>}
        {post.decommissioned && <span>✅ {t('post.decommissioned')}</span>}
        {post.treatmentgiven && <span>✅ {t('post.treatment')}</span>}
        {post.baseboardcleaned && <span>✅ {t('post.basecleaned')}</span>}
        {post.retiedcombs && <span>✅ {t('post.retiedcombs')}</span>}
        {post.superadded && <span>✅ {t('post.superadded')}</span>}
        {post.superremoved && <span>✅ {t('post.superremoved')}</span>}
        {post.queencellgiven && <span>✅ {t('post.qcellgiven')}</span>}
        {post.queencellsremoved > 0 && <span>{t('post.qcellsremoved')}: {post.queencellsremoved}</span>}
        {post.feedgiven > 0 && <span>{t('post.feedgiven')}: {post.feedgiven}</span>}
        {post.combsadded > 0 && <span>{t('post.combsadded')}: {post.combsadded}</span>}
        {post.combsremoved > 0 && <span>{t('post.combsremoved')}: {post.combsremoved}</span>}
        {post.framesharvested > 0 && <span>{t('post.honeyharvested')}: {post.framesharvested}</span>}
        {post.moved_id && <span>{movedText}</span>}
        {post.split_id && <span>{splitText}</span>}
      </div>
    </div>
  );
}

export default PostAttributes;
