import React, { useContext, useEffect, useState } from "react";
import { getMe } from "../../services/users";

const AuthContext = React.createContext(null);

export function AuthProvider(props) {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);
  const [loggingIn, setLoggingIn] = useState(false);
  
  const getUser = (token) => {
    setLoggingIn(true);
    return getMe(token).then((u) => {
      if (u) setUser(u);
    }).catch((err) => {
      console.error(err);
      clear();
    }).finally(() => setLoggingIn(false));
  }

  const login = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
  };
  const logout = () => {
    clear();
  };
  const clear = () => {
    setToken(null);
    localStorage.removeItem('token');
    setUser(null);
  };
  const refresh = () => {
    return getUser(token);
  }

  useEffect(() => {
    if (token) getUser(token);
  }, [token]);

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    let token = params.get('token');
    if (params.get('success') === 'true' && token) {
      login(token);
    }
  }, [window.location.href]);

  const auth = {user, token, login, logout, refresh, loggingIn};

  return (
    <AuthContext.Provider value={auth}>
      {props.children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
}
