import { IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useEffect, useState } from "react";
import UserAvatar from "./useravatar";
import { useAuth } from './auth/provider';
import moment from 'moment';
import EditableTextField from "./editabletextfield";
import { useDispatch, useSelector } from 'react-redux';
import { selectUserById, fetchUsersById } from '../store/users';
import { selectCommentById, updateComment } from "../store/comments";

function Comment(props) {
  const dispatch = useDispatch();
  const comment = useSelector(state => selectCommentById(state, props.comment.id));

  const user = useSelector(state => selectUserById(state, comment.user_id));
  useEffect(() => {
    if (!user) dispatch(fetchUsersById({userIds: [comment.user_id]}));
  }, [comment.user_id, user, dispatch]);

  const [editing, setEditing] = useState(false);
  const handleEditBtnClick = () => {setEditing(true)};
  const handleEdit = (text) => {
    setEditing(false);
    if (text === comment.text) return;
    dispatch(updateComment({commentId: comment.id, text}));
  };

  const auth = useAuth();
  return (
    <ListItem key={comment.id}>
      <ListItemAvatar>
        <UserAvatar user={user} />
      </ListItemAvatar>
      <EditableTextField editing={editing} initialValue={comment.text} onEdit={handleEdit}>
        <ListItemText secondary={moment(comment.updated_at).format('MMM D, h:mm a')}>
          {comment.text}
        </ListItemText>
      </EditableTextField>
      { auth.user.id === comment.user_id ?
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={handleEditBtnClick}>
            <Edit />
          </IconButton>
        </ListItemSecondaryAction>
        : null
      }
    </ListItem>
  );
}

export default Comment;
