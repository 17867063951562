import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( (theme) => ({
  frame: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: 'inherit',
    overflowY: 'scroll'
  },
  notificationList: {
    margin: '10px'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  clearButton: {
    maxWidth: '100vmin',
    minWidth: '360px',
    marginBottom: '40px'
  }
}));

export default useStyles;