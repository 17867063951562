import {List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Switch, Divider, Typography, Select, MenuItem, TextField} from '@material-ui/core';
import {Alarm, InvertColors, Notifications, Today} from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDarkMode, selectPushNotifications, togglePushNotifications, toggleTheme } from '../store/app';
import { fetchPreferences, selectPreferenceByUserId, updatePreferences } from '../store/preferences';
import pushNotificationsUtil from '../utils/pushnotifications';
import { useAuth } from './auth/provider';
import {useTranslation} from 'react-i18next';


function MonthPicker(pickerName, defaultValue=0, onChange=null) {
  const {t} = useTranslation();
  return (
    <Select name={pickerName} value={defaultValue} onChange={onChange}>
      <MenuItem value={0}>{t('prefs.jan')}</MenuItem>
      <MenuItem value={1}>{t('prefs.feb')}</MenuItem>
      <MenuItem value={2}>{t('prefs.mar')}</MenuItem>
      <MenuItem value={3}>{t('prefs.apr')}</MenuItem>
      <MenuItem value={4}>{t('prefs.may')}</MenuItem>
      <MenuItem value={5}>{t('prefs.jun')}</MenuItem>
      <MenuItem value={6}>{t('prefs.jul')}</MenuItem>
      <MenuItem value={7}>{t('prefs.aug')}</MenuItem>
      <MenuItem value={8}>{t('prefs.sep')}</MenuItem>
      <MenuItem value={9}>{t('prefs.oct')}</MenuItem>
      <MenuItem value={10}>{t('prefs.nov')}</MenuItem>
      <MenuItem value={11}>{t('prefs.dec')}</MenuItem>
    </Select>
  );
}

function Preferences() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const auth = useAuth();

  const prefs = useSelector(state => selectPreferenceByUserId(state, auth.user.id));
  const [postFrequency, setPostFrequency] = useState(prefs?.postfrequency || '');
  useEffect(() => {
    if (!prefs) dispatch(fetchPreferences()).then((p) => {
      setPostFrequency(p.payload.postfrequency)
    });
  }, [])

  const darkMode = useSelector(selectDarkMode);
  function toggleMode() {
    dispatch(toggleTheme());
  }
  const isPushNotificationSupported = pushNotificationsUtil.isSupported();
  const notifications = useSelector(selectPushNotifications);
  function toggleNotifications() {
    dispatch(togglePushNotifications());
  }

  const updateFrequency = (e) => {
    e.preventDefault();
    setPostFrequency(e.target.value);
  }
  const handlePrefUpdate = (e) => {
    dispatch(updatePreferences({...prefs, [e.target.name]:e.target.value}))
  }

  return (
    <>
      <List>
        <ListItem>
          <ListItemIcon><Alarm /></ListItemIcon>
          <ListItemText>{t('prefs.postfrequency')}</ListItemText>
          <ListItemSecondaryAction>
            <TextField name='postfrequency' value={postFrequency} style={{maxWidth:'50px'}} type="number"  
              inputProps={{style:{textAlign:'center'}}} onChange={updateFrequency} onBlur={handlePrefUpdate} />
          </ListItemSecondaryAction>
        </ListItem>      
        <ListItem>
          <ListItemIcon><Today /></ListItemIcon>
          <ListItemText>{t('prefs.stage')}</ListItemText>
        </ListItem>
        <List style={{marginLeft:'64px'}}>
          <ListItem>
            {t('prefs.stagehoney')}
            <ListItemSecondaryAction>
              {MonthPicker('month_honey', prefs?.month_honey, handlePrefUpdate)}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            {t('prefs.stagedearth')}
            <ListItemSecondaryAction>
              {MonthPicker('month_dearth', prefs?.month_dearth, handlePrefUpdate)}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            {t('prefs.stagedev')}
            <ListItemSecondaryAction>
              {MonthPicker('month_dev', prefs?.month_dev, handlePrefUpdate)}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemIcon><InvertColors /></ListItemIcon>
          <ListItemText>{t('prefs.darktheme')}</ListItemText>
          <ListItemSecondaryAction>
            <Switch edge="end" onChange={toggleMode} checked={darkMode} color="primary" />
          </ListItemSecondaryAction>
        </ListItem>
        {isPushNotificationSupported && <ListItem>
          <ListItemIcon><Notifications /></ListItemIcon>
          <ListItemText>{t('prefs.pushn')}</ListItemText>
          <ListItemSecondaryAction>
            <Switch edge="end" onChange={toggleNotifications} checked={notifications} color="primary" />
          </ListItemSecondaryAction>
        </ListItem>}
      </List>
    </>
  );
}

export default Preferences;
