import { AppBar, Badge, IconButton, Menu, MenuItem, Toolbar, Typography, useTheme } from '@material-ui/core'
import {Language, Menu as MenuIcon, Notifications} from '@material-ui/icons'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import { BASEURL } from '../services/server';
import { fetchNotifications, selectAllNotifications } from '../store/notifications';
import { useAuth } from './auth/provider';
import {useTranslation} from 'react-i18next';

function TopBar(props) {
  const {t, i18n} = useTranslation();

  const [loginURL, setLoginURL] = useState(BASEURL + '/auth/google/start?redir=' + encodeURIComponent(BASEURL))
  useEffect(() => {
    setLoginURL(BASEURL + '/auth/google/start?redir=' + encodeURIComponent(window.location.href));
  }, [window.location.href])

  const auth = useAuth();
  const dispatch = useDispatch();
  const notifications = useSelector(selectAllNotifications);
  useEffect(() => {
    if (auth.user && !notifications.length) dispatch(fetchNotifications());
  }, [notifications.length, dispatch, auth.user]);

  const theme = useTheme();

  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const handleClickMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };
  const handleSetLanguage = (lang) => {
    i18n.changeLanguage(lang);
    handleCloseMenu();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton onClick={props.onMenu}><MenuIcon /></IconButton>
          <div style={{margin: 'auto'}}>
            <Link to="/">
              <img src="/favicon.png" height="48" width="48" alt="BeeBook" />
            </Link>
          </div>
          <IconButton onClick={handleClickMenu}>
            <Language />
          </IconButton>
          <Menu anchorEl={menuAnchor} getContentAnchorEl={null} open={menuOpen} onClose={handleCloseMenu}
              anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
            <MenuItem onClick={() => handleSetLanguage('en')}>English</MenuItem>
            <MenuItem onClick={() => handleSetLanguage('hi')}>हिन्दी</MenuItem>
            <MenuItem onClick={() => handleSetLanguage('kn')}>ಕನ್ನಡ</MenuItem>
          </Menu>
        {auth.user ? 
          (<IconButton component={Link} to="/notifications">
            <Badge color="secondary" invisible={!notifications.length} badgeContent={notifications.length}>
              <Notifications />
            </Badge>
          </IconButton>) 
        : (
          <Typography variant="body2">
            <a href={loginURL} style={{color:theme.palette.text.primary, textDecoration: 'none'}}>
              <b>{t('bars.signin')}</b>
            </a>
          </Typography>
        ) }
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;