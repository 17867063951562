import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paper : {
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit', 
    height: 'inherit',
    overflowY: 'clip'
  },
  toolbar: {
    paddingRight: "0",
    minHeight: '48px'
  },
  boxStatus: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    fontSize: '0.75em',
    paddingTop: '2px',
    gap: '1px',
    gridTemplateRows: 'min-content min-content min-content min-content min-content',
    lineHeight: '1em',
    margin: '0px 0px 8px 0px'
  },
  boxName: {
    cursor: 'pointer'
  },
  subText: {
    fontSize: '.8rem',
    lineHeight: '1'
  },
  iconButton: {
    marginLeft: 'auto'
  },
  menuButton: {
    display: 'inline-block'
  },
  list : {
    flexGrow: 1,
    height: 'inherit',
    overflowY: 'scroll'
  },
  listItem : {
    padding: "4px 12px"
  },
  link: {
    textDecoration:'none', 
    width:'100%'
  }
});

export default useStyles;