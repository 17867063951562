import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import { getPreferences, setPreferences } from '../services/preferences'

const preferencesAdapter = createEntityAdapter({
  selectId: (pref) => pref.user_id
});
const initialState = preferencesAdapter.getInitialState();

export const fetchPreferences = createAsyncThunk('preferences/fetchPreferences', async () => {
  return await getPreferences();
});
export const updatePreferences = createAsyncThunk('preferences/updatePreferences', async ({postfrequency, month_honey, month_dearth, month_dev}) => {
  return await setPreferences(postfrequency, month_honey, month_dearth, month_dev);
});

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPreferences.fulfilled]: preferencesAdapter.upsertOne,
    [updatePreferences.fulfilled]: preferencesAdapter.upsertOne
  }
});

export const {
  selectById: selectPreferenceByUserId
} = preferencesAdapter.getSelectors((state) => state.preferences);

export default preferencesSlice.reducer;
