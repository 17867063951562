import {server} from './server';

export const getPosts = async (boxId, offset = 0, limit = 10) => {
  const params = {box_id: boxId, offset, limit};

  const resp = await server().get('/posts', {params});
  return resp.data;
};

export const getPost = async (postId) => {
  const params = {post_id: postId};

  const resp = await server().get('/posts', {params});
  return resp.data[0];
};

export const addPost = async (box_id, text, img, attrs) => {
  const data = { box_id, text, img, ...attrs };

  const resp = await server().post('/posts', data);
  return resp.data[0];
};

export const editPost = async (post_id, text, img, attrs) => {
  const data = { post_id, text, img, ...attrs };

  const resp = await server().put('/posts', data);
  return resp.data[0];
};

export const deletePost = async (post_id) => {
  const data = { post_id };

  const resp = await server().delete('/posts', {data});
  return resp.data;
};
