import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from "react";
import { Dialog, DialogTitle, Divider, Slide, Toolbar, useTheme } from '@material-ui/core';
import useStyles from '../styles/dialogbox';

function DialogBox(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const open = props.open || true;

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const classes = useStyles();
  return (
    <Dialog keepMounted={true} fullScreen={fullScreen} open={open} 
          TransitionComponent={Transition} className={classes.dialog}>
      <Toolbar variant="dense">
        {props.left}
        <DialogTitle>{props.title}</DialogTitle>
        {props.right}
      </Toolbar>
      <Divider />
      {props.children}
    </Dialog>
  );
}

export default DialogBox;
