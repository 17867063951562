import { useEffect } from 'react';
import {BASEURL} from '../../services/server'
import { useAuth } from './provider';
import { Redirect } from "react-router-dom";
import { Grid, Button, Typography, Card, CardMedia, CardContent, CardActions } from "@material-ui/core";
import { demoLocation, roles } from '../../utils/consts';
import {GoogleIcon} from '../icons';
import useStyles from '../../styles/login';
import {useTranslation} from 'react-i18next';

function Login(props) {
  const {t} = useTranslation();
  const classes = useStyles();

  const contactURL = '/about#:~:text=beebookcf%20%5Bat%5D%20gmail%20%5Bdot%5D%20com';
  const loginURL = BASEURL + '/auth/google/start?redir=' +
                    encodeURIComponent(window.location.href);
  const guestLoginSuffix = '&ut=g';
  const farmerLoginSuffix = '&ut=f';
  const consumerLoginSuffix = '&ut=c';

  const auth = useAuth();

  const redirectedFrom = props.location?.state?.from || "/";
  if (auth.user) {
    if (auth.user.role === roles.GUEST) {
      return (<Redirect to={"/location/" + demoLocation} />);
    }
    return (<Redirect to={redirectedFrom} />)
  }
  else if (auth.loggingIn) {
    return null;
  }
  else {
    return (
      <Grid container justify="center" alignItems="center" direction="column" style={{flexGrow: 1, overflow: 'hidden'}}>
        <div style={{height:'100%', width:'100%', overflowX:'hidden', overflowY:'scroll', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Typography variant="h2">
            {t('beebook')}
          </Typography>
          <Typography variant="h6">
            {t('caption')}
          </Typography>
          <img src="/banner.png" style={{height:'50vh', margin:'0 2%'}}/>
          <Typography variant="h5" style={{margin: '1% 2%', textAlign: 'center'}}>
            {t('launch.description')}
          </Typography>
          <a href={loginURL + guestLoginSuffix} style={{textDecoration: 'none'}}>
            <Button variant="outlined" color="primary" 
              style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', minWidth:'50vmin'}}>
              <GoogleIcon style={{margin:'0 8px 0 0'}} />
              <h3 style={{lineHeight: '1', flexGrow:'1'}}>{t('launch.signup')}</h3>
            </Button>
          </a>
          <div className={classes.cardbar}>
            <Card variant="outlined" className={classes.card}>
              <CardMedia image="/section1.png" className={classes.media} />
              <CardContent>
                <Typography variant="h6">{t('launch.user1')}</Typography>
                <Typography variant="body2" component="ul">
                  <li>{t('launch.u1pt1')}</li>
                  <li>{t('launch.u1pt2')}</li>
                  <li>{t('launch.u1pt3')}</li>
                  <li>{t('launch.u1pt4')}</li>
                </Typography>
              </CardContent>
              <CardActions>
                <a href={loginURL + farmerLoginSuffix} style={{textDecoration: 'none'}}>
                  <Button size="small" color="primary"><b>{t('launch.register')}</b></Button>
                </a>
              </CardActions>
            </Card>
            <Card variant="outlined" className={classes.card}>
              <CardMedia image="/section2.png" className={classes.media} />
              <CardContent>
                <Typography variant="h6">{t('launch.user2')}</Typography>
                <Typography variant="body2" component="ul">
                  <li>{t('launch.u2pt1')}</li>
                  <li>{t('launch.u2pt2')}</li>
                  <li>{t('launch.u2pt3')}</li>
                  <li>{t('launch.u2pt4')}</li>
                </Typography>
              </CardContent>
              <CardActions>
                <a href={loginURL + consumerLoginSuffix} style={{textDecoration: 'none'}}>
                  <Button size="small" color="primary"><b>{t('launch.explore')}</b></Button>
                </a>
              </CardActions>
            </Card>
            <Card variant="outlined" className={classes.card}>
              <CardMedia image="/section3.png" className={classes.media} />
              <CardContent>
                <Typography variant="h6">{t('launch.user3')}</Typography>
                <Typography variant="body2" component="ul">
                  <li>{t('launch.u3pt1')}</li>
                  <li>{t('launch.u3pt2')}</li>
                  <li>{t('launch.u3pt3')}</li>
                  <li>{t('launch.u3pt4')}</li>
                </Typography>
              </CardContent>
              <CardActions>
                <a href={contactURL} style={{textDecoration: 'none'}}>
                  <Button size="small" color="primary"><b>{t('launch.contactus')}</b></Button>
                </a>
              </CardActions>
            </Card>
            <Card variant="outlined" className={classes.card}>
              <CardMedia image="/section4.png" className={classes.media} />
              <CardContent>
                <Typography variant="h6">{t('launch.user4')}</Typography>
                <Typography variant="body2" component="ul">
                  <li>{t('launch.u4pt1')}</li>
                  <li>{t('launch.u4pt2')}</li>
                  <li>{t('launch.u4pt3')}</li>
                  <li>{t('launch.u4pt4')}</li>
                </Typography>
              </CardContent>
              <CardActions>
                <a href={contactURL} style={{textDecoration: 'none'}}>
                  <Button size="small" color="primary"><b>{t('launch.contactus')}</b></Button>
                </a>
              </CardActions>
            </Card>
          </div>
        </div>
      </Grid>
    );
  }
}

export default Login;
