import { Avatar } from "@material-ui/core";

function UserAvatar(props) {
  if (props.user) {
    if (props.user.img_url) {
      return (<Avatar src={props.user.img_url} />);
    }
    else {
      return (<Avatar>{props.user.name[0]}</Avatar>);
    }
  }
  else {
    return null;
  }
}

export default UserAvatar;
