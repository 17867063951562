import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import { getBoxes, addBox, editBox, moveBox, summary } from '../services/boxes'
import { selectAllPosts } from "./posts";

const boxesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.position - b.position,
});
const initialState = boxesAdapter.getInitialState();

export const fetchBoxesByLocation = createAsyncThunk('boxes/fetchBoxesByLocation', async ({locationId}) => {
  return await getBoxes(locationId);
});
export const fetchBoxes = createAsyncThunk('boxes/fetchBoxes', async () => {
  return await getBoxes();
});
export const fetchBox = createAsyncThunk('boxes/fetchBox', async ({boxId}) => {
  const boxes =  await getBoxes(null, boxId);
  return boxes?.length ? boxes[0] : null;
});
export const addNewBox = createAsyncThunk('boxes/addNewBox', async ({locationId, name}) => {
  return await addBox(locationId, name);
});
export const updateBox = createAsyncThunk('boxes/updateBox', async ({boxId, name}) => {
  return await editBox(boxId, name);
});
export const moveBoxAction = createAsyncThunk('boxes/moveBox', async ({boxId, locationId, position}) => {
  await moveBox(boxId, locationId, parseInt(position));
  return await getBoxes(locationId);
});
export const fetchSummary = createAsyncThunk('boxes/fetchSummary', async ({boxId}) => {
  return await summary(boxId);
});

const boxesSlice = createSlice({
  name: 'boxes',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBoxes.fulfilled]: boxesAdapter.upsertMany,
    [fetchBoxesByLocation.fulfilled]: boxesAdapter.upsertMany,
    [fetchBox.fulfilled]: boxesAdapter.upsertOne,
    [addNewBox.fulfilled]: boxesAdapter.addOne,
    [updateBox.fulfilled]: boxesAdapter.upsertOne,
    [moveBoxAction.fulfilled]: boxesAdapter.upsertMany
  }
});

export const {
  selectAll: selectAllBoxes,
  selectById: selectBoxById,
  selectIds: selectBoxIds
} = boxesAdapter.getSelectors((state) => state.boxes);
export const selectBoxesByLocation = createSelector(
  [selectAllBoxes, (_, locationId) => locationId],
  (boxes, locationId) => {
    return boxes?.filter(box => box.location_id === locationId) || [];
  }
);
export const selectBoxesByConsumer = createSelector(
  [selectAllBoxes, (_, consumerId) => consumerId],
  (boxes, consumerId) => {
    return boxes?.filter(box => box.consumer_id === consumerId) || [];
  }
);
export const selectBoxByPost = createSelector(
  [selectAllBoxes, selectAllPosts, (_, postId) => postId],
  (boxes, posts, postId) => {
    console.log(boxes?.length, posts?.length, postId);
    const post = posts.filter(p => p.id === postId);
    return boxes?.filter(box => box.id === post.box_id);
  }
);

export default boxesSlice.reducer;
