import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import { getComments, addComment, editComment } from '../services/comments'

const commentsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.created_at.localeCompare(a.created_at),
});
const initialState = commentsAdapter.getInitialState();

export const fetchCommentsByPost = createAsyncThunk('comments/fetchComments', async ({postId}) => {
  return await getComments(postId);
});
export const addNewComment = createAsyncThunk('comments/addNewComment', async ({postId, text}) => {
  return await addComment(postId, text);
});
export const updateComment = createAsyncThunk('comments/updateComment', async ({commentId, text}) => {
  return await editComment(commentId, text);
});

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCommentsByPost.fulfilled]: commentsAdapter.upsertMany,
    [addNewComment.fulfilled]: commentsAdapter.addOne,
    [updateComment.fulfilled]: commentsAdapter.upsertOne
  }
});

export const {
  selectAll: selectAllComments,
  selectById: selectCommentById,
  selectIds: selectCommentIds
} = commentsAdapter.getSelectors((state) => state.comments);
export const selectCommentsByPost = createSelector(
  [selectAllComments, (_, postId) => postId],
  (comments, postId) => {
    return comments?.filter(comment => comment.post_id === postId) || [];
  }
);

export default commentsSlice.reducer;
