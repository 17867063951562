import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import { getLocation, getLocations, addLocation, editLocation, setLocationNotice, getLocationSummary } from '../services/locations'
import { selectAllBoxes } from "./boxes";
import { selectAllPosts } from "./posts";

const locationsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.created_at.localeCompare(a.created_at),
});
const initialState = locationsAdapter.getInitialState();

export const fetchLocationById = createAsyncThunk('locations/fetchLocationById', async ({locationId}) => {
  return await getLocation(locationId);
});
export const fetchLocations = createAsyncThunk('locations/fetchLocations', async () => {
  return await getLocations();
});
export const addNewLocation = createAsyncThunk('locations/addNewLocation', async ({name}) => {
  return await addLocation(name);
});
export const updateLocation = createAsyncThunk('locations/updateLocation', async ({locationId, name}) => {
  return await editLocation(locationId, name);
});
export const updateLocationNotice = createAsyncThunk('locations/updateLocationNotice', async ({locationId, notice}) => {
  return await setLocationNotice(locationId, notice);
});
export const fetchLocationSummary = createAsyncThunk('locations/fetchLocationSummary', async({locationId}) => {
  return await getLocationSummary(locationId);
});

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLocationById.fulfilled]: locationsAdapter.upsertOne,
    [fetchLocations.fulfilled]: locationsAdapter.upsertMany,
    [addNewLocation.fulfilled]: locationsAdapter.addOne,
    [updateLocation.fulfilled]: locationsAdapter.upsertOne,
    [updateLocationNotice.fulfilled]: locationsAdapter.upsertOne
  }
});

export const {
  selectAll: selectAllLocations,
  selectById: selectLocationById,
  selectIds: selectLocationIds
} = locationsAdapter.getSelectors((state) => state.locations);
export const selectLocationsByUser = createSelector(
  [selectAllLocations, (_, userId) => userId],
  (locations, userId) => {
    return locations?.filter(location => location.farmer_id === userId) || [];
  }
);
export const selectNameSortedLocationsByUser = createSelector(
  [selectAllLocations, (_, userId) => userId],
  (locations, userId) => {
    const locs = locations?.filter(location => location.farmer_id === userId) || [];
    return locs.sort((a,b) => a.name.localeCompare(b.name));
  }
);
export const selectLocationByBox = createSelector(
  [selectAllLocations, selectAllBoxes, (_, boxId) => boxId],
  (locations, boxes, boxId) => {
    const box = boxes.filter(b => b.id === boxId);
    return box.length ? locations.filter(l => l.id === box[0].location_id)[0] || [] : []
  }
);
export const selectLocationByPost = createSelector(
  [selectAllLocations, selectAllBoxes, selectAllPosts, (_, postId) => postId],
  (locations, boxes, posts, postId) => {
    const post = posts.filter(p => p.id === postId)[0] || {};
    const box = boxes.filter(b => b.id === post.box_id);
    return box.length ? locations.filter(l => l.id === box[0].location_id)[0] || [] : [];
  }
);

export default locationsSlice.reducer;
