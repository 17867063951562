import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import { getPost, getPosts, addPost, editPost, deletePost } from '../services/posts'

const postsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.created_at.localeCompare(a.created_at),
});
const initialState = postsAdapter.getInitialState();

export const fetchPostById = createAsyncThunk('posts/fetchPostById', async ({postId}) => {
  return await getPost(postId);
});
export const fetchPostsByBox = createAsyncThunk('posts/fetchPosts', async ({boxId, offset}) => {
  return await getPosts(boxId, offset);
});
export const addNewPost = createAsyncThunk('posts/addNewPost', async ({boxId, text, img, attrs}) => {
  return await addPost(boxId, text, img, attrs);
});
export const updatePost = createAsyncThunk('posts/updatePost', async ({postId, text, img, attrs}) => {
  return await editPost(postId, text, img, attrs);
});
export const erasePost = createAsyncThunk('posts/erasePost', async ({postId}) => {
  await deletePost(postId);
  return postId;
});

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPostById.fulfilled]: postsAdapter.upsertOne,
    [fetchPostsByBox.fulfilled]: postsAdapter.upsertMany,
    [addNewPost.fulfilled]: postsAdapter.addOne,
    [updatePost.fulfilled]: postsAdapter.upsertOne,
    [erasePost.fulfilled]: postsAdapter.removeOne
  }
});

export const {
  selectAll: selectAllPosts,
  selectById: selectPostById,
  selectIds: selectPostIds
} = postsAdapter.getSelectors((state) => state.posts);
export const selectPostsByBox = createSelector(
  [selectAllPosts, (_, boxId) => boxId],
  (posts, boxId) => {
    return posts?.filter(post => post.box_id === boxId) || [];
  }
);

export default postsSlice.reducer;
