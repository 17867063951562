import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      overflow: 'auto'
    },
  },
  cardLeft: {
    [theme.breakpoints.down('sm')]: {
      flex: '1 0 100%'
    },
    flex: '1 1 60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardRight: {
    [theme.breakpoints.down('sm')]: {
      flex: '1 0 100%'
    },
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 40%',
    overflowY: 'scroll'
  },
  content: {
    flex: '0 0 auto',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  cardActions: {margin: 'auto 0', borderRadius: '4px', padding:'16px'},
  textField: {
    width: '100%', 
    margin: 'auto', 
    '& div': {padding: '8px 14px'}
  },
  commentList : {
    flex: '1'
  }
}));

export default useStyles;