import { Button, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { useAuth } from "./auth/provider";
import UserAvatar from "./useravatar";
import { districts, states } from '../utils/india';
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../store/users";
import { useHistory } from "react-router-dom";
import {useTranslation} from 'react-i18next';

function Profile(props) {
  const {t} = useTranslation();
  const auth = useAuth();
  const user = auth.user;

  const [phone, setPhone] = useState(user?.phone);
  const [phoneError, setPhoneError] = useState(user?.phone.toString().length!==10);
  const handlePhoneChange = (e) => {
    setPhone(Number.parseInt(e.target.value) || '');
    if (e.target.value.toString().length === 10) {
      setPhoneError(false)
    }
    else {
      setPhoneError(true)
    }
  }
  const stateDefault = user?.state || 16; //set karnataka if state is andaman
  const distDefault = user?.state ? user.district : 122; //set mangalore if state is andaman
  const [state, setState] = useState(stateDefault);
  const handleStateChange = (e) => {
    setState(e.target.value);
    setDistrict(0);
  }
  const [district, setDistrict] = useState(distDefault);
  const handleDistrictChange = (e) => {
    setDistrict(e.target.value);
  }
  const [address, setAddress] = useState(user?.address);
  const [addressError, setAddressError] = useState(false || !(user?.address))
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    if (e.target.value === '') {
      setAddressError(true);
    }
    else {
      setAddressError(false);
    }
  }
  const [pin, setPin] = useState(user?.pin);
  const [pinError, setPinError] = useState(user?.pin.toString().length!==6);
  const handlePinChange = (e) => {
    setPin(e.target.value);
    if (e.target.value.toString().length === 6) {
      setPinError(false);
    }
    else {
      setPinError(true);
    }
  }
  const [farmerExperience, setFarmerExperience] = useState(user?.farmer_experience || 0);
  const handleFarmerExperienceChange = (e) => {
    setFarmerExperience(e.target.value);
  }
  const [farmerBeeType, setFarmerBeeType] = useState(user?.farmer_beetype || 0);
  const handleFarmerBeeTypeChange = (e) => {
    setFarmerBeeType(e.target.value);
  }
  const [farmerNumColonies, setFarmerNumColonies] = useState(user?.farmer_numcolonies || 0);
  const handleFarmerNumColoniesChange = (e) => {
    setFarmerNumColonies(e.target.value);
  }
  const [farmerAvgHarvest, setFarmerAvgHarvest] = useState(user?.farmer_avgharvest || 0);
  const handleFarmerAvgHarvestChange = (e) => {
    setFarmerAvgHarvest(e.target.value);
  }

  let role = '';
  switch(user?.role) {
    case 1:
      role = t('profile.rolefarmer');
      break;
    case 2:
      role = t('profile.roleconsumer');
      break;
    case 3:
    default:
      role = t('profile.roleguest');
      break;
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const handleSave = () => {
    dispatch(updateProfile({
      phone, state, district, address, pin, farmerExperience, 
      farmerBeeType, farmerNumColonies, farmerAvgHarvest
    })).then(() => {
      auth.refresh().then(() => history.push('/'));
    });
  }

  return (
    <div style={{height:'inherit', overflowY:'scroll'}}>
      <div style={{display:'flex',flexDirection:'column',justifyContent:'center', alignItems:'center', padding:'1%'}}>
        <UserAvatar user={user} />
        <Typography variant="h4">{user?.name}</Typography>
        <Typography variant="body2">{role}</Typography>
        <br/>
        <table>
          <tbody>
            <tr>
              <td>{t('profile.email')}</td>
              <td><TextField variant="outlined" value={user?.email} disabled  /></td>
            </tr>
            <tr>
              <td>{t('profile.phone')}</td>
              <td>
                <TextField variant="outlined" inputProps={{ inputMode: 'numeric' }} error={phoneError}
                  helperText={t('profile.numdigits', {num:'10'})} value={phone} onChange={handlePhoneChange} />
              </td>
            </tr>
            <tr>
              <td>{t('profile.state')}</td>
              <td>
                <Select variant="outlined" value={state} onChange={handleStateChange}>
                  {states.map(s => (<MenuItem key={s} value={states.indexOf(s)}>{s}</MenuItem>))}
                </Select>
              </td>
            </tr>
            <tr>
              <td>{t('profile.city')}</td>
              <td>
                <Select variant="outlined" value={district || 0} onChange={handleDistrictChange}>
                  {districts[states[state]].map(d => (<MenuItem key={d} value={districts[states[state]].indexOf(d)}>{d}</MenuItem>))}
                </Select>
              </td>
            </tr>
            <tr>
              <td>{t('profile.address')}</td>
              <td>
                <TextField variant="outlined" value={address} helperText={t('profile.addresshelp')}
                error={addressError} onChange={handleAddressChange} />
              </td>
            </tr>
            <tr>
              <td>{t('profile.pin')}</td>
              <td>
                <TextField variant="outlined" inputProps={{ inputMode: 'numeric' }} error={pinError} 
                helperText={t('profile.numdigits', {num:'6'})} value={pin} onChange={handlePinChange} />
              </td>
            </tr>
            {user?.role === 1 && (<tr>
              <td>{t('profile.years')}</td>
              <td><TextField variant="outlined" value={farmerExperience} onChange={handleFarmerExperienceChange} type="number" /></td>
            </tr>)}
            {user?.role === 1 && (<tr>
              <td>{t('profile.beetype')}</td>
              <td>
                <Select variant="outlined" value={farmerBeeType} onChange={handleFarmerBeeTypeChange}>
                  <MenuItem value={0}>{t('profile.apiscerana')}</MenuItem>
                  <MenuItem value={1}>{t('profile.apismellifera')}</MenuItem>
                </Select>
              </td>
            </tr>)}
            {user?.role === 1 && (<tr>
              <td>{t('profile.numcolonies')}</td>
              <td>
                <Select variant="outlined" value={farmerNumColonies} onChange={handleFarmerNumColoniesChange}>
                  <MenuItem value={0}>{t('profile.lessthan5')}</MenuItem>
                  <MenuItem value={1}>5 - 50</MenuItem>
                  <MenuItem value={2}>50 - 500</MenuItem>
                  <MenuItem value={3}>{t('profile.morethan500')}</MenuItem>
                </Select>
              </td>
            </tr>)}
            {user?.role === 1 && (<tr>
              <td>{t('profile.avgharvest')}</td>
              <td>
                <TextField variant="outlined" value={farmerAvgHarvest} 
                  onChange={handleFarmerAvgHarvestChange} type="number" />
              </td>
            </tr>)}
          </tbody>
        </table>
        <br/>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={phoneError || pinError || addressError}>
          {t('profile.save')}
        </Button>
      </div>
    </div>
  );
}

export default Profile;
