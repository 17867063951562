import { Grid, Typography } from "@material-ui/core";
import useStyles from "../styles/about";
import {useTranslation} from 'react-i18next';

function About(props) {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.div}>
      <Grid container justify="center" className={classes.topgrid}>
        <Typography variant="h2">
          {t('beebook')}
        </Typography>
        <Typography variant="h6">
          {t('caption')}
        </Typography>
      </Grid>
      <Typography variant="body1">
        {t('about.abouttext')}
      </Typography>
      <br/>
      <Typography variant="h4">
        {t('about.vision')}
      </Typography>
      <Typography variant="body1">
        {t('about.vision1')}
      </Typography>
      <br/>
      <Typography variant="h4">
        {t('about.goals')}
      </Typography>
      <Typography variant="body1" component="ul">
          <li>{t('about.goal1')}</li>
          <li>{t('about.goal2')}</li>
          <li>{t('about.goal3')}</li>
          <li>{t('about.goal4')}</li>
          <li>{t('about.goal5')}</li>
          <li>{t('about.goal6')}</li>
      </Typography>
      <br/>
      <Typography variant="h4">
        {t('about.contactus')}
      </Typography>
      <Typography variant="body1">
        {t('about.contacttext')}
      </Typography>
      <br/>
    </div>
  );
}

export default About;
