import FarmerHome from './farmerhome'
import MyBoxes from './myboxes'
import { useAuth } from './auth/provider';
import { roles } from '../utils/consts'
import { useEffect } from 'react';
import pushNotificationsUtil from "../utils/pushnotifications";
import { useSelector } from 'react-redux';
import { selectPushNotifications } from '../store/app';
import { Redirect } from 'react-router-dom';

function Home() {
  const pushNotificationPref = useSelector(selectPushNotifications);
  useEffect(() => {
    if (pushNotificationPref) pushNotificationsUtil.refresh(); 
  }, [])

  const {user} = useAuth();
  // force profile completion
  if (user.phone === '' || !(user.state || user.city) || !user.address || !user.pin) {
    return <Redirect to="/profile" />;
  }
  
  switch (user.role) {
    default:
    case roles.FARMER:
      return (<FarmerHome />);
    case roles.CONSUMER:
      return (<MyBoxes />);    
  }
}

export default Home;