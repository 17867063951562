import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  div: {
    height: 'inherit',
    overflowY: 'scroll',
    padding: '0 8px 0 8px'
  },
  grid: {
    height: 'inherit',
    overflowY: 'scroll',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '0 16px' 
  },
  topgrid: {
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 0 16px 0'
  },
  link: {
    textDecoration: 'none'
  }
});

export default useStyles;