import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import { getNotifications, clearNotifications } from '../services/notifications'

const notificationsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.created_at.localeCompare(a.created_at),
});
const initialState = notificationsAdapter.getInitialState();

export const fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async () => {
  return await getNotifications();
});
export const deleteNotifications = createAsyncThunk('notifications/deleteNotifications', async () => {
  return await clearNotifications();
});

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchNotifications.fulfilled]: notificationsAdapter.upsertMany,
    [deleteNotifications.fulfilled]: notificationsAdapter.removeAll
  }
});

export const {
  selectAll: selectAllNotifications,
  selectById: selectNotificationById,
  selectIds: selectNotificaitonIds
} = notificationsAdapter.getSelectors((state) => state.notifications);

export default notificationsSlice.reducer;
