import { configureStore } from '@reduxjs/toolkit'
import appReducer from './app'
import usersReducer from './users'
import locationsReducer from './locations'
import boxesReducer from './boxes'
import postsReducer from './posts'
import commentsReducer from './comments'
import notificationsReducer from './notifications'
import preferencesReducer from './preferences'

const store = configureStore({
  reducer: {
    app: appReducer,
    users: usersReducer,
    locations: locationsReducer,
    boxes: boxesReducer,
    posts: postsReducer,
    comments: commentsReducer,
    notifications: notificationsReducer,
    preferences: preferencesReducer
  }
});

export default store;