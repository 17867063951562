import {server} from './server';

export const getPreferences = async () => {
  const resp = await server().get('/preferences');
  return resp.data[0];
};

export const setPreferences = async(postfrequency, month_honey, month_dearth, month_dev) => {
  const data = {
    postfrequency, month_honey, month_dearth, month_dev
  }
  const resp = await server().put('/preferences', data);
  return resp.data[0];
}
