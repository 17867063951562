import TopBar from './components/topbar'
import SideDrawer from './components/sidedrawer'
import Home from './components/home'
import Location from './components/location'
import Notifications from './components/notifications'
import Preferences from './components/preferences'
import Profile from './components/profile'
import PostDetailDialog from './components/postdetaildialog'
import CreatePostDialog from './components/createpostdialog'
import { Paper } from '@material-ui/core'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import useStyles from './styles/app'
import themes from './styles/themes'
import { AuthProvider } from './components/auth/provider'
import PrivateRoute from './components/auth/privateroute'
import Login from './components/auth/login'
import Logout from './components/auth/logout'
import About from './components/about'
import EditPostDialog from './components/editpostdialog'
import { useSelector } from 'react-redux'
import { selectDarkMode } from './store/app'

function App() {
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  }
  const isDarkModeOn = useSelector(selectDarkMode);
  const classes = useStyles();
  return (
    <ThemeProvider theme={isDarkModeOn ? themes.dark : themes.light}>
      <AuthProvider>
        <Router>
          <Paper className={classes.paper}>
            <TopBar onMenu={toggleDrawer} />
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/about" component={About} />
              <PrivateRoute path="/logout" component={Logout} />
              <PrivateRoute path="/location/:locationId" component={Location} />
              <PrivateRoute path="/notifications" component={Notifications} />
              <PrivateRoute path="/preferences" component={Preferences} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/" component={Home} />
            </Switch>
          </Paper>
          <PrivateRoute path="(.*)\/post\/:postId" exact component={PostDetailDialog} />
          <PrivateRoute path="(.*)\/post\/:postId\/edit" exact component={EditPostDialog} />
          <PrivateRoute path="(.*)\/box\/:boxId\/post" component={CreatePostDialog} />
          <SideDrawer mobileShow={isDrawerVisible} onClose={toggleDrawer} />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
