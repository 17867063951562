import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 0,
    height: 'inherit',
    width: 'inherit',
    scrollSnapType: 'x mandatory'
  },
  listItem: {
    width: "95vw",
    maxWidth: "300px", 
    height: "100%", 
    padding: "5px 5px",
    scrollSnapAlign: 'start'
  }
});

export default useStyles;