import Post from './post';
import EditableTextField from './editabletextfield';
import { Divider, IconButton, LinearProgress, List, ListItem, Menu, MenuItem, Paper, Toolbar, Typography, useTheme } from "@material-ui/core";
import {MoreVert, PostAdd} from '@material-ui/icons';
import useStyles from '../styles/box';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '../components/auth/provider';
import { selectPostsByBox, fetchPostsByBox } from '../store/posts';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersById, selectUserById } from '../store/users';
import { updateBox, moveBoxAction, fetchSummary } from '../store/boxes';
import { pathnamePrefix } from '../utils/browser';
import { fetchLocationById, selectLocationById } from '../store/locations';
import MoveBoxDialog from './moveboxdialog';
import moment from 'moment';
import { fetchPreferences, selectPreferenceByUserId } from '../store/preferences';
import {useTranslation} from 'react-i18next';

function getHealth(box, t) {
  switch(box?.health) {
    default:
    case 0:
      return "💚 " + t('box.healthy');
    case 1:
      return "🧡 " + t('box.diseased');
  }
}

function getSeason(box, t) {
  switch(box?.season) {
    default:
    case 0:
      return "🌺 " + t('box.dev');
    case 1:
      return "🍯 " + t('box.honey');
    case 2:
      return "🥣 " + t('box.dearth');
  }
}

function getUsage(box, t) {
  switch(box?.usage) {
    default:
    case 0:
      return "🟢 " + t('box.active');
    case 1:
      return "⚪ "+ t('box.empty');
    case 2:
      return "⚫ " + t('box.decommissioned');
  }
}

function getQueenPresent(box, t) {
  switch(box?.queenpresent) {
    default:
    case 0:
      return "⚠️ " + t('box.qmissing');
    case 1:
      return "🐝 " + t('box.qpresent');
    case 2:
      return "⚠️ " + t('box.qunknown');
  }
}

// "⚪🟢🟡🟠🔴🟤⚫"

function Box(props) {
  const {t} = useTranslation();

  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const dispatch = useDispatch();

  const prefs = useSelector(state => selectPreferenceByUserId(state, auth.user.id));
  useEffect(() => {
    if (!prefs) dispatch(fetchPreferences());
  }, [])

  const boxId = props.box.id;
  const [boxName, setBoxName] = useState(props.box.name);
  
  const posts = useSelector(state => selectPostsByBox(state, boxId));
  const [postsInitialised, setPostsInitialised] = useState(false);
  useEffect(() => {
    if (!posts.length) dispatch(fetchPostsByBox({boxId})).then(() => setPostsInitialised(true));
  }, [boxId, posts.length, dispatch]);

  const [summary, setSummary] = useState(null);
  useEffect(() => {
    if (!summary) {
      dispatch(fetchSummary({boxId})).then(data => {
        setSummary(data.payload);
      });
    } 
  }, [])

  const [ownerName, setOwnerName] = useState('');
  const ownerId = (auth.user.id === props.box.farmer_id) ? props.box.consumer_id : props.box.farmer_id;
  const owner = useSelector(state => selectUserById(state, ownerId));
  useEffect(() => {
    if (!ownerId) return;
    if (!owner) dispatch(fetchUsersById({userIds: [ownerId]}));
    else {
      setOwnerName(owner.name);
    }
  }, [owner, dispatch, ownerId]);

  const locationId = props.box.location_id;
  const location = useSelector(state => selectLocationById(state, locationId));
  useEffect(() => {
    if (!location) dispatch(fetchLocationById({locationId}));
  }, [locationId, location?.id, dispatch]);

  const [editing, setEditing] = useState(false);
  const handleClick = () => {setEditing(true);};
  const handleEdit = (text) => {
    setEditing(false);
    if (props.box.name === text) return;
    dispatch(updateBox({boxId, name:text}));
    setBoxName(text);
  };

  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const handleClickMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const [moveBoxDialogOpen, setMoveBoxDialogOpen] = useState(false);
  const handleMoveBoxClick = () => {
    handleCloseMenu();
    setMoveBoxDialogOpen(true);
  }
  const handleMoveBoxCancel = () => {
    setMoveBoxDialogOpen(false);
  }
  const handleMoveBoxMove = (locationId, position) => {
    setMoveBoxDialogOpen(false);
    dispatch(moveBoxAction({boxId, locationId, position}));
  }

  const [showProgress, setShowProgress] = useState(false);
  const [postsFetched, setPostsFetched] = useState(false);
  const handleScroll = (event) => {
    const contentHeight = event.target.scrollHeight;
    const contentScrolledUp = event.target.scrollTop;
    const boxHeight = event.target.offsetHeight;
    const pixelsUnseenByUser = contentHeight - contentScrolledUp - boxHeight;
    if (pixelsUnseenByUser >= 300) {
      setPostsFetched(false);
    }
    else if (pixelsUnseenByUser < 300 && !postsFetched) {
      setPostsFetched(true);
      setShowProgress(true);
      dispatch(fetchPostsByBox({boxId, offset: posts.length})).then(() => setShowProgress(false));
    }
  }

  const [buttonBG, setButtonBG] = useState({backgroundColor:theme.palette.background.default});
  useEffect(() => {
    if (moment(posts[0]?.created_at).add(parseInt(prefs?.postfrequency || 7), 'days') < moment(new Date())) {
      setButtonBG({backgroundColor:theme.palette.background.tinted})
    }
  }, [postsInitialised])

  return (
    <Paper className={classes.paper} variant="outlined"
      style={{backgroundColor:theme.palette.background.default}}>
      <Toolbar className={classes.toolbar}>
        {(auth.user.id === props.box.farmer_id) ? 
        <>
          <div>          
            <EditableTextField variant="outlined" editing={editing} onEdit={handleEdit} initialValue={props.box.name}>
              <Typography variant="body1" onClick={handleClick} className={classes.boxName}>
                <b>{boxName}</b>
              </Typography>
            </EditableTextField>
            <Typography variant="subtitle1" className={classes.subText}>{ownerName}</Typography>
          </div>
          <div className={classes.iconButton}>
            <IconButton component={Link} 
              to={{pathname: pathnamePrefix() + "/box/" + boxId + "/post", state: location}}
              style={buttonBG}>
              <PostAdd />
            </IconButton>
            <div className={classes.menuButton}>
              <IconButton onClick={handleClickMenu}>
                <MoreVert />
              </IconButton>
              <Menu anchorEl={menuAnchor} getContentAnchorEl={null} open={menuOpen} onClose={handleCloseMenu}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                <MenuItem onClick={handleMoveBoxClick}>{t('box.changeposition')}</MenuItem>
              </Menu>
              <MoveBoxDialog positionOnly defaultPosition={props.box.position} defaultLocation={location} 
                open={moveBoxDialogOpen} onMove={handleMoveBoxMove} onCancel={handleMoveBoxCancel} />
            </div>
          </div>
        </> :
        <div>
          <Typography variant="body1"><b>{boxName}</b></Typography>
          {location &&
            <Typography color="textPrimary" variant="subtitle1" className={classes.subText} component={Link} to={"/location/" + location.id}>
              {location.name}
            </Typography>
          }
        </div>
        }
      </Toolbar>
      {/* <Divider /> */}
      <Toolbar className={classes.boxStatus}>
        <span>{getUsage(props.box, t)}</span>
        <span>{getHealth(props.box, t)}</span>
        <span>{getSeason(props.box, t)}</span>
        <span>{getQueenPresent(props.box, t)}</span>
        <span>{t('box.numframesfilled', {num: props.box.filledbroodframes})}</span>
        <span>{t('box.numsupers', {num: props.box?.numsupers})}</span>
        <span>{t('box.numsplits', {num: summary?.splits || 0})}</span>
        <span>{t('box.numframesharvested', {num: summary?.yield || 0})}</span>
        <span>{t('box.totalreports')}: {summary?.reports || 0}</span>
        <span>{t('box.nextexam')}: {moment(posts[0]?.created_at).add(parseInt(prefs?.postfrequency || 7), 'days').format('MMM D, YY')}</span>
      </Toolbar>
      <LinearProgress style={{visibility: showProgress ? 'visible' : 'hidden'}} />
      <Divider />
      <List className={classes.list} onScroll={handleScroll}>
        {posts.map((post) => (
          <ListItem className={classes.listItem} key={post.id}>
            <Link to={{state: post, pathname: (pathnamePrefix() + '/post/' + post.id)}} className={classes.link}>
              <Post post={post} box={props.box} />
            </Link>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

export default Box;