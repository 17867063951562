import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useHistory } from "react-router";
import DialogBox from "./dialogbox";
import PostDetail from "./postdetail";
import {useTranslation} from 'react-i18next';

function PostDetailDialog(props) {
  const {t} = useTranslation();
  const history = useHistory();
  const handleClose = () => {
    if (history.location.state)
      history.goBack();
    else {
      const pn = window.location.pathname;
      history.push(pn.slice(0, pn.indexOf('post') - 1));
    }
  }

  const Right = (<IconButton onClick={handleClose} style={{marginLeft: 'auto'}}><Close /></IconButton>);

  return (
    <DialogBox title={t('dialogs.post')} right={Right}>
      <PostDetail location={props.location} />
    </DialogBox>
  );
}

export default PostDetailDialog;
