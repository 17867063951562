import {server} from './server';

export const storeSubscription = async (subscription) => {
  const data = { subscription };

  await server().post('/pn', data);
  return;
};

export const unsubscribe = async () => {
  await server().delete('/pn');
  return;
};
