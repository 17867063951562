import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      width: '65vmin',
      maxWidth: '250px'
    }
  },
  name: {
    marginLeft: '8px'
  }
});

export default useStyles;