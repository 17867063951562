import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "./provider";

function R(props) {
  const location = useLocation();
  return <Redirect to={{pathname:"/login", state:{from:location}}} />;
}

function PrivateRoute({component, ...rest}) {
  const auth = useAuth();
  return (
    <Route {...rest} component={auth.user ? component : R} />
  );
}

export default PrivateRoute;
