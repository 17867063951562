import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBoxes, selectBoxesByConsumer } from "../store/boxes";
import { useAuth } from "./auth/provider";
import BoxList from "./boxlist";
import {useTranslation} from 'react-i18next';

function MyBoxes() {
  const {t} = useTranslation();
  const auth = useAuth();

  const boxes = useSelector(state => selectBoxesByConsumer(state, auth.user.id));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!boxes.length) dispatch(fetchBoxes());
  }, [boxes.length]);
  //logout on 401?

  return (
    <>
      <Typography variant="h5" align="center" color="textPrimary">{t('consumerhome.myboxes')}</Typography>
      <Typography variant="body1" align="center" color="textSecondary"></Typography>
      <BoxList boxes={boxes} />
    </>
  );
}

export default MyBoxes;