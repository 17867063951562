import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import pushNotificationsUtil from "../utils/pushnotifications"

const initialState = {
  darkMode: localStorage.getItem('darkMode') === 'true' || false,
  pushNotifications: localStorage.getItem('pushNotifications') === 'true' || false
};

export const togglePushNotifications = createAsyncThunk('app/togglePushNotifications', async (_, {getState}) => {
  const state = getState();
  return await pushNotificationsUtil.setEnabled(!state.pushNotifications)
});

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleTheme(state) {
      localStorage.setItem('darkMode', !state.darkMode);
      state.darkMode = !state.darkMode;
    }
  },
  extraReducers: {
    [togglePushNotifications.fulfilled]: (state, action) => {
      console.log("Fulfilled", action.payload);
      const success = action.payload;
      if (success) {
        localStorage.setItem('pushNotifications', !state.pushNotifications);
        state.pushNotifications = !state.pushNotifications;
      }
      else {
        console.warn('Could not enable push notifications!');
      }
    }
  }
});

export const selectDarkMode = (state) => state.app.darkMode;
export const selectPushNotifications = (state) => state.app.pushNotifications;

export const { toggleTheme } = appSlice.actions;

export default appSlice.reducer;
