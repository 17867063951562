import { Card, CardActions, CardContent, CardHeader, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import useStyles from "../styles/farmerhome";
import { useAuth } from "./auth/provider";
import { Add, SortByAlpha } from "@material-ui/icons";
import EditableTextField from "./editabletextfield";
import {useDispatch, useSelector} from 'react-redux';
import { addNewLocation, fetchLocations, selectLocationsByUser, selectNameSortedLocationsByUser } from "../store/locations";
import moment from 'moment';
import { fetchSummary } from "../store/users";
import { ToggleButton } from '@material-ui/lab';
import {useTranslation} from 'react-i18next';

function FarmerHome() {
  const {t} = useTranslation();
  const classes = useStyles();
  const auth = useAuth();
  const dispatch = useDispatch();

  const [sortByName, setSortByName] = useState(false);
  const toggleSortByName = (e) => {
    setSortByName(!sortByName);
  }

  const locationSelector = sortByName ? selectNameSortedLocationsByUser : selectLocationsByUser;
  const locations = useSelector(state => locationSelector(state, auth.user.id));
  useEffect(() => {
    if (!locations.length) dispatch(fetchLocations());
  }, [locations.length, dispatch]);
  //401 response?

  const [editing, setEditing] = useState(false);
  const handleClick = (e) => {
    setEditing(true);
  };
  const handleEdit = (text) => {
    setEditing(false);
    if (!text) return;
    dispatch(addNewLocation({name:text}));
  };

  const history = useHistory();
  const handleLocationClick = (locationId) => {
    history.push('/location/' + locationId);
  }

  // const columns = [
  //   {field: 'name', headerName: 'Name'},
  //   {field: 'created_at', headerName: 'Date added'}
  // ]
  const [summary, setSummary] = useState(null);
  useEffect(() => {
    if (!summary) {
      dispatch(fetchSummary()).then(data => {
        setSummary(data.payload);
      })
    }
  }, []);

  return (
    <div className={classes.div}>
      <Typography variant="h6" align="center" color="textPrimary">{t('farmerhome.home')}</Typography>
      <div className={classes.cardcontainer}>
        <TableContainer component={Card} elevation={2} className={classes.table}>
          <CardHeader title={t('farmerhome.locations')} action={
            <ToggleButton onClick={toggleSortByName} selected={sortByName}>
              <SortByAlpha />
            </ToggleButton>
          } />
          <CardContent className={classes.tableContent}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><b>{t('farmerhome.name')}</b></TableCell>
                  <TableCell align="right"><b>{t('farmerhome.dateadded')}</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locations.map(location => 
                  (<TableRow className={classes.tableRow} onClick={() => handleLocationClick(location.id)} key={location.id}>
                    <TableCell>{location.name}</TableCell>
                    <TableCell align="right">{moment(location.created_at).format('MMM D, YY')}</TableCell>
                  </TableRow>)
                )}
              </TableBody>
            </Table>
          </CardContent>
          <CardActions>
            {auth.user ? 
              (<Card className={classes.addcard} variant="outlined" onClick={handleClick}>
                <CardContent className={classes.addcardContent}>
                  <EditableTextField editing={editing} onEdit={handleEdit} center={true} placeholder={t('farmerhome.newlocationndefault')}>
                    <Add /> {t('farmerhome.newlocation')}
                  </EditableTextField>
                </CardContent>
              </Card>) : null
            }
          </CardActions>
        </TableContainer>
        <Card elevation={2} className={classes.table}>
          <CardHeader title={t('farmerhome.summary')} />
          <CardContent className={classes.summarycontainer}>
            <div>{summary?.activeLocations} {t('farmerhome.activelocations')}</div>
            <div>{summary?.inactiveLocations} {t('farmerhome.inactivelocations')}</div>
            <div>{summary?.activeBoxes} {t('farmerhome.activeboxes')}</div>
            <div>{summary?.inactiveBoxes} {t('farmerhome.inactiveboxes')}</div>
            <div>{summary?.numExams} {t('farmerhome.inspections')}</div>
            <div>{summary?.numComments} {t('farmerhome.comments')}</div>
            <div>{summary?.numFramesHarvested} {t('farmerhome.framesharvested')}</div>
            <div>{summary?.numDaysWorked} {t('farmerhome.daysworked')}</div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default FarmerHome;