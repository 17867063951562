import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    width:'inherit',
    fontSize: 'small'
  },
  cardMedia: {
    height:0, 
    paddingTop: '100%'
  },
  cardContent: {
    padding: '8px'
  },
  cardActions: {
    padding:'0px',
    '& > :not(:first-child)': {
      margin: '0px',
      padding: '8px'
    }
  },
  actionText: {
    marginRight:'auto',
    padding:'8px',
    fontSize: '0.7em'
  }
});

export default useStyles;