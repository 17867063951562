import { Paper, Toolbar, useTheme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useState } from "react";
import useStyles from "../styles/addboxbutton";
import EditableTextField from "./editabletextfield";
import {useTranslation} from 'react-i18next';

function AddBoxButton(props) {
  const {t} = useTranslation();
  
  const [editing, setEditing] = useState(false);
  const handleClick = (e) => {
    setEditing(true);
  };
  const handleEdit = (text) => {
    setEditing(false);
    if (text) props.onAdd(text);
  };

  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.div}>
      <Paper variant="outlined" className={classes.paper} onClick={handleClick}
        style={{backgroundColor:theme.palette.background.default}}>
        <Toolbar className={classes.toolbar}>
          <EditableTextField editing={editing} onEdit={handleEdit} center={true} placeholder="Enter box name">
            <Add /> {t('location.addbox')}
          </EditableTextField>
        </Toolbar>
      </Paper>
    </div>
  );
}

export default AddBoxButton;
