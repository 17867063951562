import axios from 'axios';

const origin = window.location.origin;
export const BASEURL = origin.includes("http://localhost") ? "http://localhost:5000" : origin;
export const API_ROUTE = '/api';

export const server = () => {
  return axios.create({
    baseURL: BASEURL + API_ROUTE,
    headers: {Authorization: localStorage.getItem('token')}
  });
};
