import {server} from './server';

export const getMe = async (token) => {
  const resp = await server().get('/users/me');
  return resp.data[0];
};

export const editProfile = async(phone, state, district, address, pin, farmer_experience, farmer_beetype, farmer_numcolonies, farmer_avgharvest) => {
  const data = {
    phone, state, district, address, pin,
    farmer_experience, farmer_beetype, farmer_numcolonies, farmer_avgharvest
  }
  const resp = await server().put('/users/me', data);
  return resp.data[0];
}

export const getUsers = async (userIds) => {
  const params = {user_ids: userIds.join(',')};

  const resp = await server().get('/users', {params});
  return resp.data;
};

export const getSummary = async() => {
  const resp = await server().get('/users/summary');
  return resp.data;
};
