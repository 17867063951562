import { Card, CardActions, CardContent, IconButton, List, TextField, Typography, useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import useStyles from '../styles/postdetail';
import { useParams } from "react-router";
import Comment from "./comment";
import { Send } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchPostById, selectPostById } from "../store/posts";
import { addNewComment, fetchCommentsByPost, selectCommentsByPost } from "../store/comments";
import PostAttributes from "./postattributes";
import moment from "moment";
import { fetchBoxes, selectBoxById, selectBoxByPost } from "../store/boxes";
import { fetchLocations, selectLocationByPost } from "../store/locations";
import {useTranslation} from 'react-i18next';


function PostDetail(props) {
  const {t} = useTranslation();
  const { postId } = useParams();
  
  const post = useSelector(state => selectPostById(state, postId));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!post) dispatch(fetchPostById({postId}));
  }, [postId, post, dispatch]);

  const location = useSelector(state => selectLocationByPost(state, postId));
  const box = useSelector(state => selectBoxById(state, post?.box_id));
  useEffect(() => {
    if (!location) dispatch(fetchLocations());
    if (!box) dispatch(fetchBoxes());
  }, [])

  const comments = useSelector(state => selectCommentsByPost(state, postId));
  useEffect(() => {
    if (!comments.length) dispatch(fetchCommentsByPost({postId}));
  }, [postId, comments.length, dispatch]);

  const [comment, setComment] = useState('');
  const handleChange = (e) => {
    setComment(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submit();
    }
  };
  const submit = () => {
    if (!comment) return;
    dispatch(addNewComment({postId, text: comment}));
    setComment('');
  };

  const theme = useTheme();
  const classes = useStyles();
  return (
    <Card elevation={0} className={classes.card}>
      {post && post.img_url && 
        <div className={classes.cardLeft}>
          <img src={post.img_url} className={classes.content} alt="" />
        </div>
      }
      
      <div className={classes.cardRight} style={{backgroundColor: theme.palette.background.default}}>
        <Typography color="textSecondary" variant="body2" style={{padding:'8px 16px'}}>
          {box && location && box.name + '@' + location.name + ' | '}
          {post && moment(post.created_at).format('MMM D, h:mm a')}
        </Typography>
        {post && post.text && <CardContent>{post.text}</CardContent>}
        {post && post.post_id && <PostAttributes post={post} />}
        <CardActions className={classes.cardActions}>
          <TextField multiline label={t('post.writecomment')} className={classes.textField}
            variant="outlined" value={comment} onChange={handleChange} onKeyPress={handleKeyPress}
            InputProps= {{endAdornment: (
                <IconButton color="primary" onClick={submit}><Send /></IconButton>
              )}}
          />
        </CardActions>
        <List style={{backgroundColor: theme.palette.background.default}} className={classes.commentList}>
          {comments.map((comment) => (
            <Comment comment={comment} key={comment.id} />
          ))}
        </List>
      </div>
    </Card>
  );
}

export default PostDetail;