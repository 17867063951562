import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import { editProfile, getMe, getSummary, getUsers } from '../services/users'

const usersAdapter = createEntityAdapter();
const initialState = usersAdapter.getInitialState();

export const fetchMe = createAsyncThunk('users/fetchMe', async ({token}) => {
  return await getMe(token);
});
export const updateProfile = createAsyncThunk('users/updateProfile', async ({phone, state, district, address, pin, farmerExperience, farmerBeeType, farmerNumColonies, farmerAvgHarvest}) => {
  return await editProfile(phone, state, district, address, pin, farmerExperience, farmerBeeType, farmerNumColonies, farmerAvgHarvest);
});
export const fetchUsersById = createAsyncThunk('users/fetchUsersById', async ({userIds}) => {
  return await getUsers(userIds);
});
export const fetchSummary = createAsyncThunk('/users/fetchSummary', async () => {
  return await getSummary();
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMe.fulfilled]: usersAdapter.upsertOne,
    [updateProfile.fulfilled]: usersAdapter.upsertOne,
    [fetchUsersById.fulfilled]: usersAdapter.upsertMany
  }
});

export const {
  selectById: selectUserById,
  selectIds: selectUserIds
} = usersAdapter.getSelectors((state) => state.users);

export default usersSlice.reducer;
