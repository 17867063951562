import { IconButton, Paper, Toolbar, Typography, useTheme } from "@material-ui/core";
import useStyles from "../styles/locationsummary";
import EditableTextField from "./editabletextfield";
import moment from "moment";
import { useEffect, useState } from "react";
import { Edit } from "@material-ui/icons";
import { useAuth } from "./auth/provider";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocationSummary, updateLocationNotice } from "../store/locations";
import { fetchPreferences, selectPreferenceByUserId } from "../store/preferences";
import {useTranslation} from 'react-i18next';

function LocationSummary(props) {
  const {t} = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const dispatch = useDispatch();

  const location = props.location;
  
  const [editing, setEditing] = useState(false);
  const handleEdit = (notice) => {
    setEditing(false);
    if (notice === location.notice) return;
    dispatch(updateLocationNotice({locationId:location.id, notice}))
  }
  const handleEditBtnClick = (e) => {
    setEditing(true);
  }

  const [summary, setSummary] = useState(null);
  useEffect(() => {
    if (location.id) {
      dispatch(fetchLocationSummary({locationId: location.id})).then(data => {
        setSummary(data.payload);
      });
    } 
  }, [location.id])

  const prefs = useSelector(state => selectPreferenceByUserId(state, auth.user.id));
  useEffect(() => {
    if (!prefs) dispatch(fetchPreferences());
  }, [])

  return (
    <Paper className={classes.paper} style={{backgroundColor:theme.palette.background.default}}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="body1"><b>{t('location.summary')}</b></Typography>
      </Toolbar>
      <div className={classes.boxSummary}>
        <span>{t('location.activeboxes')}: {summary?.active}</span>
        <span>{t('location.inactiveboxes')}: {summary?.inactive}</span>
        <span>{t('location.indev')}: {summary?.development}</span>
        <span>{t('location.prodhoney')}: {summary?.honey}</span>
        <span>{t('location.totalboxes')}: {summary?.total}</span>
        <span>{t('location.framesthisyear')}: {summary?.framesty}</span>
        {/* <span>Stands in use: 6</span>
        <span>Stands empty: 0</span> */}
        <span>{t('location.lastvisited')}: {moment(summary?.lastvisited).format('MMM D, YY')}</span>
        <span>{t('location.nextvisit')}: {moment(summary?.lastvisited).add(parseInt(prefs?.postfrequency || 7), 'days').format('MMM D, YY')}</span>
      </div>
      <Toolbar className={classes.toolbar}>
        <Typography variant="body1"><b>{t('location.notice')}</b></Typography>
      </Toolbar>
      <EditableTextField className={classes.textField} editing={editing} initialValue={location?.notice} onEdit={handleEdit}>
        <Typography className={classes.noticeText} variant="body2">
          {location?.notice || t('location.noticedefault', {locationName: location?.name})}
        </Typography>
      </EditableTextField>
      <div className={classes.noticeAction}>
        <Typography className={classes.timestamp} color="textSecondary" variant="subtitle2">
          {location?.notice_ts && moment(location.notice_ts).format('MMM D, h:mm a')}
        </Typography>
        { auth.user.id === location?.farmer_id ?
            <IconButton edge="end" onClick={handleEditBtnClick}>
              <Edit />
            </IconButton>
          : null
        }
      </div>
    </Paper>
  );
}

export default LocationSummary;