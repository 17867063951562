import { Chip, Drawer, Divider, List, ListItem, Toolbar, ListItemText, ListItemIcon, Typography } from "@material-ui/core";
import { Home, Place, People, PowerSettingsNew, Settings } from '@material-ui/icons'
import UserAvatar from './useravatar';
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useStyles from "../styles/sidedrawer";
import { useAuth } from "./auth/provider";
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocationById, fetchLocations, selectAllLocations, selectLocationsByUser } from '../store/locations';
import { selectBoxesByConsumer } from "../store/boxes";
import {demoLocation, roles} from '../utils/consts';
import {useTranslation} from 'react-i18next';

function SideDrawer(props) {
  const {t} = useTranslation();

  const classes = useStyles();
  const auth = useAuth();

  const dispatch = useDispatch();

  const farmerLocations = useSelector(state => selectLocationsByUser(state, auth.user?.id)); //farmer
  useEffect(() => {
    if (!auth.user || auth.user.role !== roles.FARMER) return;
    dispatch(fetchLocations());
  }, [auth.user, farmerLocations?.length, dispatch]);

  const consumerBoxes = useSelector(state => selectBoxesByConsumer(state, auth.user?.id)); //consumer
  const allLocations = useSelector(selectAllLocations);
  useEffect(() => {
    if (!auth.user || auth.user.role !== roles.CONSUMER) return;
    consumerBoxes.forEach((box) => dispatch(fetchLocationById({locationId: box.location_id})));
  }, [auth.user, consumerBoxes?.length, dispatch]);
  
  const handleClick = (e) => {
    props.onClose();
  };

  const locationsToRender = (consumerBoxes?.length) ? allLocations : farmerLocations;
  const drawer = (
    <>
      <Toolbar style={{textDecoration:'none'}}>
          <UserAvatar user={auth.user} />
          <Typography variant="h6" color="textPrimary" className={classes.name}>{auth.user ? auth.user.name : null}</Typography>
      </Toolbar>
      {auth.user ? (<Chip component={Link} to="/profile" onClick={handleClick} label={t('bars.profile')} variant="outlined" style={{width:'50%', marginBottom:'4px', alignSelf:'center'}} />) : null}
      <Divider />
      <List>
        <ListItem button component={Link} to="/" onClick={handleClick}>
          <ListItemIcon><Home/></ListItemIcon>
          <ListItemText primary={t('bars.home')} />
        </ListItem>
      </List>
      <Divider />
      <List>
        {locationsToRender.length > 0 && locationsToRender.map((location) => (
          <ListItem key={location.id} button component={Link} to={"/location/" + location.id} onClick={handleClick}>
            <ListItemIcon><Place /></ListItemIcon>
            <ListItemText primary={location.name} />
          </ListItem>
        ))}
        {auth.user && <ListItem key={demoLocation} button component={Link} to={"/location/" + demoLocation} onClick={handleClick}>
            <ListItemIcon><Place /></ListItemIcon>
            <ListItemText primary={t('bars.demofarm')} />
        </ListItem>}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleClick} component={Link} to={"/about"}>
          <ListItemIcon><People /></ListItemIcon>
          <ListItemText primary={t('bars.about')} />
        </ListItem>
        { auth.user ?
        (<>
        <Divider />
        <ListItem button onClick={handleClick} component={Link} to="/preferences">
          <ListItemIcon><Settings /></ListItemIcon>
          <ListItemText primary={t('bars.prefs')} />
        </ListItem>
        <ListItem button onClick={handleClick} component={Link} to="/logout">
          <ListItemIcon><PowerSettingsNew /></ListItemIcon>
          <ListItemText primary={t('bars.signout')} />
        </ListItem></>) : null}
      </List>
    </>
  );

  return (
    <>
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.mobileShow}
          onClose = {props.onClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className={classes.drawer}
        >
            {drawer}
        </Drawer>
      
    </>
  );
}
  
export default SideDrawer;