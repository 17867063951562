import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import BoxList from './boxlist';
import { Typography } from '@material-ui/core';
import { useAuth } from './auth/provider';
import EditableTextField from './editabletextfield';
import useStyles from '../styles/location';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocationById, selectLocationById, updateLocation } from '../store/locations';
import { fetchUsersById, selectUserById } from '../store/users';
import { fetchBoxesByLocation, selectBoxesByLocation } from '../store/boxes';
import LocationSummary from './locationsummary';

function Location() {
  const auth = useAuth();
  const classes = useStyles();
  const { locationId } = useParams();
  
  const dispatch = useDispatch();
  const location = useSelector(state => selectLocationById(state, locationId));
  const farmer = useSelector(state => selectUserById(state, location?.farmer_id));
  const [userOwnsLocation, setUserOwnsLocation] = useState(false);
  useEffect(() => {
    if (!location) dispatch(fetchLocationById({locationId}));
    else {
      setUserOwnsLocation(location.farmer_id === auth.user.id);
      if (!farmer) dispatch(fetchUsersById({userIds: [location.farmer_id]}));
    }
  }, [locationId, location, farmer, auth.user.id, dispatch]);

  const boxes = useSelector(state => selectBoxesByLocation(state, locationId));
  useEffect(() => {
    dispatch(fetchBoxesByLocation({locationId}));
  }, [dispatch, locationId, boxes.length]);

  const [editing, setEditing] = useState(false);
  const handleClick = () => {
    setEditing(true);
  }
  const handleEdit = (text) => {
    setEditing(false);
    if (text === location.name) return;
    dispatch(updateLocation({locationId, name:text}));
  }
  
  return (
    <>
      <div className={classes.wrapper}>
        { userOwnsLocation ?
          (<EditableTextField editing={editing} onEdit={handleEdit} initialValue={location.name} center={true}>
              <Typography variant="h5" component="span" align="center" color="textPrimary" onClick={handleClick} className={classes.locationName}>
                {location && location.name}
              </Typography>
          </EditableTextField> ) :
          (<>
            <Typography variant="h5" align="center" color="textPrimary">
              {location && location.name}
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary">
              {farmer && farmer.name}
            </Typography>
          </>)
        }
      </div>
      <div style={{display:'flex', flexDirection:'row', overflowX:'scroll', overflowY:'clip'}}>
        {location && <LocationSummary location={location} />}
        <BoxList boxes={boxes} allowAdd={userOwnsLocation} />
      </div>
    </>
  );
}

export default Location;