import { IconButton, TextField } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { useState } from "react";
import useStyles from "../styles/editabletextfield";

function EditableTextField(props) {
  const classes = useStyles();

  const [text, setText] = useState(props.initialValue || '');

  const handleChange = (e) => {setText(e.target.value);};
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {finish();}
  };
  const handleBlur = (e) => {finish();};
  const finish = () => {
    props.onEdit(text);
    if (!props.initialValue) setText('');
  };

  const handleFocus = (e) => {
    const t = e.target.value;
    e.target.value = '';
    e.target.value = t;
  };
  
  let inputProps={};
  if (props.center) {
    inputProps.style = {textAlign: 'center'};
  }

  return (
    <>
      { props.editing ?
        <TextField placeholder={props.placeholder} value={text} variant={props.variant || "standard"} className={classes.textField}
          multiline autoFocus onFocus={handleFocus}
          onChange={handleChange} onKeyPress={handleKeyPress} onBlur={handleBlur} inputProps={inputProps}
          InputProps={{endAdornment: (<IconButton color="primary" onClick={finish}><Send /></IconButton>)}} />
        :
        props.children
      }
    </>
  );
}

export default EditableTextField;
