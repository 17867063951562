import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    overflow: 'auto',
    alignItems: 'flex-start'
  },
  camera: {
    [theme.breakpoints.down('sm')]: {
      flex: '1 0 100%'
    },
    flex: '1 0 60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '25vh',
    width: '100%',
    '& video': {
      width: 'inherit'
    }
  },
  caption: {
    [theme.breakpoints.down('sm')]: {
      flex: '1 0 100%'
    },
    flex: '1 0 40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderRadius: '4px'
  },
  fields: {
    display: 'flex',
    flexFlow: 'row wrap',
    padding: '0 16px'
  },
  fieldscol: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '50%'
  },
  selectbox: {
    margin: '4px 2px',
    width: '100%'
  },
  filledframes: {
    margin: '4px 16px',
    display: 'flex',
    flexDirection: 'row'
  },
  filledframetextfield: {
    margin: '0 2px'
  },
  harvesttextfield: {
    margin: '8px 16px 8px 16px'
  },
  btnrow: {
    margin: '4px 16px'
  },
  btnrowbtn: {
    width: '100%'
  },
  sectionname: {
    padding: '0 8px',
    margin: '8px 0 8px 0'
  },
  buttonBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    margin: '10px',
    padding: '20px',
    backdropFilter: 'contrast(0.5)',
    borderRadius: '4px',
    '& input': {
      display: 'none'
    }
  },
  cardMedia: {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    flex: '0 0 auto'
  },
  textField: {
    margin: '16px'
  },
  fileUpload: {
    '& input': {
      display: 'none'
    }
  }
}));

export default useStyles;