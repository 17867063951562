import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paper : {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '300px',
    flexShrink: 0,
    height: 'inherit',
    overflowY: 'clip',
    margin: '5px'
  },
  toolbar: {
    paddingRight: "0",
    minHeight: '48px'
  },
  boxSummary: {
    margin: '0 16px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    fontSize: '0.75em',
    paddingTop: '2px',
    gap: '2px',
    gridTemplateRows: 'min-content min-content min-content min-content min-content',
    minHeight: '80px'
  },
  subText: {
    fontSize: '.8rem',
    lineHeight: '1'
  },
  noticeText: {
    margin: '0 16px', 
  },
  textField: {
    width: 'auto', 
    '& div': {padding: '8px 16px'}
  },
  noticeAction: {
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center',
    margin: '0 16px'
  },
  timestamp: {
    marginRight:'auto', 
    fontSize:'0.7em'
  }
});

export default useStyles;