import {server} from './server';

export const getComments = async (postId) => {
  const params = {post_id: postId};

  const resp = await server().get('/comments', {params});
  return resp.data;
};

export const addComment = async (post_id, text) => {
  const data = { post_id, text };

  const resp = await server().post('/comments', data);
  return resp.data[0];
};

export const editComment = async (comment_id, text) => {
  const data = { comment_id, text };
  
  const resp = await server().put('/comments', data);
  return resp.data[0];
};
