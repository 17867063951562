export const DEFAULT_ATTRS = {
  eggs: false, 
  larvae: false, 
  drones: false, 
  sealedworkers: false, 
  queenspotted: false, 
  queenmissing: false,
  newconstruction: false, 
  dry: false, 
  swarmed: false, 
  absconded: false,
  died: false,
  filledbroodframes: 0, 
  filledsuperframes: 0, 
  queencells: 0, 
  symptom: 0,

  familygiven: false,
  treatmentgiven: false, 
  baseboardcleaned: false, 
  retiedcombs: false,
  commissioned: false,
  decommissioned: false,
  superadded: false, 
  superremoved: false, 
  queencellgiven: false, 
  queencellsremoved: 0, 
  feedgiven: 0,
  framesharvested: 0, 
  combsadded: 0, 
  combsremoved: 0, 
  moved_id: null,
  moved_position: 0,
  isolated_id: null, 
  filtered_id: null, 
  merged_id: null, 
  split_id: null,
  split_numframes: 0
}
