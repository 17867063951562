import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  cardbar: {
    margin: '3% 0', 
    width: '100%', 
    display:'flex', 
    flexDirection:'row', 
    flexWrap:'wrap', 
    justifyContent:'space-evenly', 
    alignItems:'center'
  },
  card: {
    margin:'2%', 
    minWidth: '300px', 
    maxWidth: '90%'
  },
  media: {
    height: '250px'
  }
});

export default useStyles;