import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paper: {
    display:"flex",
    flexDirection:"column",
    height:"100%"
  }
});

export default useStyles;