import { deepOrange, orange } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles'

const light = createMuiTheme({
  palette: {
    type: 'light',
    primary: orange,
    background: {
      default: '#f8f8f0',
      tinted: '#ffbd5b'
    },
    secondary: {
      main: '#184600'
    }
  }
});

const dark = createMuiTheme({
  palette: {
    type: 'dark',
    primary: deepOrange,
    background: {
      tinted: '#74270f'
    },
    secondary: {
      main: '#184600'
    }
  }
});

const themes = { light, dark };
export default themes;