import {ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import UserAvatar from './useravatar';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersById, selectUserById } from '../store/users';
import {useTranslation} from 'react-i18next';

function Notification(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(state => selectUserById(state, props.item.user_id));
  useEffect(() => {
    if (!user) dispatch(fetchUsersById({userIds: [props.item.user_id]}));
  }, [props.item.user_id, user, dispatch]);

  return (
    <ListItem>
      <ListItemAvatar>
        <UserAvatar user={user} />
      </ListItemAvatar>
      <ListItemText secondary={moment(props.item.created_at).format('MMM D, h:mm a')}>
        {user && user.name} {t('notifications.commented')}: <i>{props.item.text}</i>
      </ListItemText>
    </ListItem>
  ); 
}

export default Notification;
