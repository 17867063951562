import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  textField: {
    '& div': {
      padding: '0px 4px'
    }
  }
});

export default useStyles;