import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  div : {
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit',
    height: 'inherit',
    overflowY: 'clip'
  },
  paper: {
    cursor: 'pointer'
  },
  textField: {
    width: '90%'
  },
  toolbar: {
    padding: "0",
    justifyContent: 'center'
  },
  iconButton: {
    margin: 'auto'
  }
});

export default useStyles;