import { IconButton } from "@material-ui/core";
import { ArrowBack, Done } from "@material-ui/icons";
import { useHistory, useParams } from "react-router";
import DialogBox from "./dialogbox";
import PostEditor from "./posteditor";
import { useDispatch } from 'react-redux';
import { addNewPost } from '../store/posts';
import { fetchBox } from "../store/boxes";
import {useTranslation} from 'react-i18next';

function CreatePostDialog(props) {
  const {t} = useTranslation();
  const { boxId } = useParams();
  
  const history = useHistory();
  function close() {
    if (history.location.state)
      history.goBack();
    else {
      const pn = window.location.pathname;
      history.push(pn.slice(0, pn.indexOf('box') - 1));
    }
  }
  
  const data = {}
  function onChange(text, img, attrs) {
    data.text = text;
    data.img = img;
    data.attrs = attrs;
  }

  const handleClose = () => {
    close();    
  }
  const dispatch = useDispatch();
  const handleDone = () => {
    //send data
    dispatch(addNewPost({boxId, text:data.text, img:data.img, attrs:data.attrs})).then(p=>{
      dispatch(fetchBox({boxId:p.payload.box_id}))
    });
    close();
  }
  const Left = (<IconButton onClick={handleClose} style={{marginRight: 'auto'}}><ArrowBack /></IconButton>);
  const Right = (<IconButton onClick={handleDone} style={{marginLeft: 'auto'}}><Done /></IconButton>);

  return (
    <DialogBox title={t('dialogs.newpost')} left={Left} right={Right}>
      <PostEditor onChange={onChange} boxId={boxId} />
    </DialogBox>
  );
}

export default CreatePostDialog;
