import {server} from './server';

export const getBoxes = async (locationId, boxId) => {
  const params = {};
  if (locationId) {
    params.location_id = locationId;
  }
  if (boxId) {
    params.box_id = boxId;
  }

  const resp = await server().get('/boxes', {params});
  return resp.data;
};

export const addBox = async (location_id, name) => {
  const data = { location_id, name };

  const resp = await server().post('/boxes', data);
  return resp.data[0];
};

export const editBox = async (box_id, name) => {
  const data = { box_id, name };

  const resp = await server().put('/boxes', data);
  return resp.data[0];
};

export const moveBox = async (box_id, location_id, position) => {
  const data = { box_id, location_id, position };

  const resp = await server().post('/boxes/move', data);
  return resp.data[0];
};

export const summary = async (box_id) => {
  const params = {box_id};

  const resp = await server().get('/boxes/summary', {params});
  return resp.data[0];
};
