import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  div: {
    height: 'inherit',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cardcontainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  summarycontainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  addcard: {
    margin: '0px',
    // minWidth: '300px',
    width: '100%',
    border: '0',
    // maxWidth: '100vmin',
    textAlign: 'center',
    cursor: 'pointer'
  },
  addcardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    width: '45vw',
    minWidth: '350px',
    margin: '8px'
  },
  tableContent: {
    padding: '0px'
  },
  tableRow: {
    cursor: 'pointer'
  }
});

export default useStyles;