import { useEffect } from "react";
import { Redirect } from "react-router";
import { useAuth } from "./provider";

function Logout(props) {
  const auth = useAuth();
  useEffect(() => {
    auth.logout();
  });

  return (
    <Redirect to="/login" />
  );
}

export default Logout;
