import { List, ListItem } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { addNewBox } from "../store/boxes";
import useStyles from "../styles/boxlist";
import AddBoxButton from "./addboxbutton";
import Box from "./box";

function BoxList(props) {
  const {locationId} = useParams();
  const classes = useStyles();

  const dispatch = useDispatch();
  function handleAdd(name) {
    dispatch(addNewBox({locationId, name}));    
  }

  const listRef = useRef(null);
  useEffect(() => {
    listRef.current.scroll(0, 0);
  }, [locationId]);

  return(
    <List className={classes.list} ref={listRef}>
      {props.boxes.length > 0 && props.boxes.map((box) => (
        <ListItem className={classes.listItem} key={box.id}><Box box={box} /></ListItem>          
      ))}
      {props.allowAdd &&
        <ListItem className={classes.listItem}><AddBoxButton onAdd={handleAdd} /></ListItem>
      }
    </List>
  );
}

export default BoxList;
