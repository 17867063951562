import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectBoxesByLocation } from "../store/boxes";
import { selectLocationsByUser } from "../store/locations";
import useStyles from "../styles/moveboxdialog";
import { useAuth } from "./auth/provider";
import {useTranslation} from 'react-i18next';

function SplitBoxDialog(props) {
  const {t} = useTranslation();
  const open = props.open;

  const auth = useAuth();
  const boxes = useSelector((state) => selectBoxesByLocation(state, props.location?.id));

  const [selectedBoxId, setSelectedBoxId] = useState(props.defaultBox?.id);
  const [selectBoxDisabled, setSelectBoxDisabled] = useState(true);
  const handleBoxChange = (event) => {
    setSelectedBoxId(event.target.value);
    if (event.target.value === props.defaultBox?.id) {
      setSelectBoxDisabled(true)
    }
    else {
      setSelectBoxDisabled(false)
    }
  }

  const [numFrames, setNumFrames] = useState(props.defaultNumFrames || 3);
  const [error, setError] = useState(false);
  const handleNumFramesChange = (event) => {
    setNumFrames(event.target.value);
    const val = parseInt(event.target.value);
    if (val && val > 0) {
      setError(false);
    }
    else {
      setError(true);
    }
  }

  const cancel = () => {
    setSelectedBoxId(props.defaultBox?.id);
    setNumFrames(props.defaultNumFrames || 3);
    setError(false);
    props.onCancel();
  };

  const classes = useStyles();
  return (
    <Dialog open={open} onClose={cancel}>
      <DialogTitle>{t('dialogs.splitbox')}</DialogTitle>

      <DialogContent>
        <InputLabel id="box-label-id">
          Box
          <Select 
            label={t('dialogs.box')} 
            className={classes.selectMenu}
            value={selectedBoxId}
            onChange={handleBoxChange}
          >
            {boxes.filter(box => box.id !== props.defaultBox?.id).map((box) => (
              <MenuItem key={box.id} value={box.id}>{box.name}</MenuItem>
            ))}
          </Select>
        </InputLabel>

        <InputLabel id="numframes-label-id">
          {t('dialogs.framesgiven')}:<br/>
          <TextField value={numFrames} 
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            error={error} onChange={handleNumFramesChange} 
            helperText={t('dialogs.numberhelp')}
            disabled={props.boxOnly} />
        </InputLabel>
      </DialogContent>

      <DialogActions>
        <Button onClick={cancel}>{t('dialogs.cancel')}</Button>
        <Button disabled={error || selectBoxDisabled} onClick={() => props.onSplit(selectedBoxId, numFrames)}>{t('dialogs.split')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SplitBoxDialog;